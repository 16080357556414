export default {
  namespaced: true,
  state: {
    selected: [],
    sideIsSelected: false,
    lastSelected: null
  },
  mutations: {
    setSelected(state, payload) {
      state.selected = payload;
    },
    setSideSelected(state, selected) {
      state.sideIsSelected = selected;
    },
    setLastSelected(state, val) {
      state.lastSelected = val;
    }
  }
};
