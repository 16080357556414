export default {
  namespaced: true,
  state: {
    selected: [],
    marginIsSelected: false,
    lastSelected: null,
    negativeSelected: false,
  },
  mutations: {
    setSelected(state, payload) {
      state.selected = payload;
    },
    setMarginSelected(state, selected) {
      state.marginIsSelected = selected;
    },
    setLastSelected(state, val) {
      state.lastSelected = val;
    },
    setNegativeSelected(state, selected) {
      state.negativeSelected = selected;
    }
  }
};
