// is to be used in 'Page' class at 'gp-ui/src/builder/canvas/Page/index.js'
// share methods with other 'Page' mixins (i.e pageBlocksMixin, pageLoadingMixin, pageHelperMixin etc)

import Importer from '@/builder/canvas/Importer';
import pageUtils from '@/builder/canvas/pageUtils';
import proxySite from '@/builder/canvas/proxy-min-temp';
import embeds from '@/builder/data/embeds';
import store from '@/builder/data/store';
import textUtils from '@groovepages/gd-canvas/elements/textUtils';
import utils from '@groovepages/gd-canvas/utils';
import { customBase64Encode } from '@pages/shared/utils';
import { MD5 } from 'crypto-js';

export default {
  async prepareContent(mergeCSS = false) {
    if (!this.contentFetched) {
      await this.loadContent();
    }
    let pageContent;
    if (this.blocksContainer) {
      pageContent = this.blocksContainer.cloneNode(true);
    } else {
      pageContent = document.createElement('div');
      pageContent.innerHTML = this.content;
    }
    embeds.resetEmbeds(pageContent, this.pageId); // Reset Executed Embeds
    textUtils.destroyEditor(pageContent); // Quell Quill JS
    await pageUtils.refreshGlobalBlocks(pageContent); // Check latest global blocks updates

    pageUtils.cleanContent(pageContent); // Clean content from dev classes
    utils.cleanCanvas(pageContent); // Clean content from dev classes

    if (mergeCSS) {
      const globalStyles = document.createElement('style');
      globalStyles.innerHTML = store.getters.globalStyleSheet;
      globalStyles.setAttribute('data-gp-styled-element', true);
      pageContent.prepend(globalStyles);
    }
    this.setupBlockAnchor(pageContent);
    const blocks = pageContent.querySelectorAll('[data-gp-block]');
    let content = pageContent.innerHTML;
    const pageLinks = this.getPageLinks(content);
    const popupLinks = this.getPopupLinks(content);

    if (!this.stylesProcessed) {
      const parsedContent = await Importer.setInternalStyles(content);
      this.custom_css = parsedContent.baseStyles;
      content = parsedContent.content;
      this.stylesProcessed = true;
    }
    return {
      pageContent: content,
      noBlocks: !blocks.length,
      pageLinks: [...new Set(pageLinks)],
      popupLinks: [...new Set(popupLinks)]
    };
  },

  getAppliedGlobalStyles() {
    const applied = {};
    let pageContent = document.createElement('div');
    if (this.blocksContainer) {
      pageContent = this.blocksContainer;
    } else {
      pageContent.innerHTML = this.content;
    }
    Object.keys(store.state.site?.userStyles || {}).forEach(style => {
      if (pageContent.querySelectorAll(`.global-style-${style}`).length) {
        applied[style] = store.state.site.userStyles[style];
      }
    });
    return applied;
  },

  populatePageBuilderSettings(page, includeGlobalStyles) {
    if (includeGlobalStyles) {
      const globalStyles = this.getAppliedGlobalStyles();
      page.builder_settings.globalStyles = JSON.stringify(globalStyles);
    }

    const codeEmbeds = this.getEmbeds();
    const webhooks = this.getWebhooks();
    const integrations = this.getIntegrations();
    const componentConfigs =
      this.componentConfigs || store.state.pages.configs[this.pageId] || {};
    const componentDatas =
      this.componentDatas || store.getters.componentDatas || {};
    page.builder_settings.componentConfigs = this.filterDeletedConfigs(
      componentConfigs
    );
    page.builder_settings.componentDatas = this.filterDeletedComponentDatas(
      componentDatas
    );
    page.builder_settings.embeds = this.filterDeletedEmbeds(embeds.embeds[this.pageId]);
    if (codeEmbeds !== null) page.builder_settings.codeEmbeds = codeEmbeds;
    if (webhooks !== null) page.builder_settings.webhooks = webhooks;
    if (integrations !== null)
      page.builder_settings.integrations = integrations;
  },

  async prepForSave(includeGlobalStyles) {
    const page = JSON.parse(JSON.stringify(this.settings));
    page.builder_settings = this.getBuilderSettings();
    this.populatePageBuilderSettings(page, includeGlobalStyles);

    page.content = '';
    page.checksum = '';

    try {
      const {
        pageContent: markup,
        pageLinks,
        popupLinks
      } = await this.prepareContent();
      if (markup) {
        // prevent encoding already encoded content
        page.content = markup.includes('</')
          ? customBase64Encode(markup)
          : markup;
        page.checksum = MD5(page.content).toString();
      }
      const globalBlocks = this.getGlobalBlockIds();
      if (globalBlocks) page.builder_settings.globalBlocks = globalBlocks;
      page.builder_settings.pageLinks = pageLinks;
      page.builder_settings.popupLinks = popupLinks;
    } catch (e) {
      console.error(e);
    }
    return page;
  },

  async getPopupHtml(isPublishing) {
    const popupContainer = document.createElement('div');
    let delay = 0;
    popupContainer.innerHTML = await store.getters.popups.reduce(
      async (previous, popup) => {
        const markup = await previous;
        if (isPublishing && popup.shouldShow(this.pageId)) {
          delay += 1;
          const content = await popup.getContent();
          let finalDelay = delay * 500;
          if (
            ['Entry', 'Exit'].includes(popup.popup.type) ||
            content.includes('EMBEDCODE')
          )
            finalDelay = 0;
          return (
            markup +
            pageUtils.getPopupMarkup(
              content,
              popup.pageId,
              popup.popupSettings.width || 1020,
              finalDelay,
              popup.popupSettings.leavingAnimation,
              popup.popupSettings.closeButtonSize
            )
          );
        }
        return markup;
      },
      ''
    );
    return popupContainer.innerHTML;
  },

  async pageContentSettings(inBuilder, isPublishing) {
    const popupHTML = await this.getPopupHtml(isPublishing);

    const { code: proxyCode } = await proxySite;

    return pageUtils.getPageContentSettings(
      this,
      {
        ...store.state.site,
        onPageSiteSettings: store.getters.onPageSiteSettings,
        pageId: this.pageId,
        siteId: store.state.site.settings.id,
        popups: popupHTML,
        globalStyles: store.getters.globalStyleSheet,
        proxyCode
      },
      inBuilder
    );
  },

  filterDeletedComponentDatas(data = {}) {
    if (!this.contents) return JSON.stringify(data);

    const availableComponentIds = [];
    this.contents
      .querySelectorAll('[data-gp-component-id]')
      .forEach(element => {
        availableComponentIds.push(
          element.getAttribute('data-gp-component-id')
        );
      });
    Object.keys(data || {}).forEach(componentId => {
      if (!availableComponentIds.includes(componentId))
        delete data[componentId];
    });
    return JSON.stringify(data);
  },

  filterDeletedConfigs(componentConfigs) {
    if (!this.contents) return JSON.stringify(componentConfigs);

    const availableComponentIds = [];
    this.contents
      .querySelectorAll('[data-gp-component-id]')
      .forEach(component => {
        availableComponentIds.push(
          component.getAttribute('data-gp-component-id')
        );
      });

    Object.keys(componentConfigs || {}).forEach(componentId => {
      if (!availableComponentIds.includes(componentId))
        delete componentConfigs[componentId];
    });
    return JSON.stringify(componentConfigs);
  },

  filterDeletedEmbeds(embeds) {
    if (this.contents) {
      const availableEmbedIds = [];
      this.contents
        .querySelectorAll('[data-gp-embed]')
        .forEach(embed => {
          availableEmbedIds.push(embed.getAttribute('data-gp-embed'));
        });
  
      Object.keys(embeds || {}).forEach(embedId => {
        if (!availableEmbedIds.includes(embedId)) delete embeds[embedId];
      });
    }

    return customBase64Encode(JSON.stringify(embeds || {}));
  },

  async getFullPage({ screenshot, publishing, checkRedirect } = {}) {
    const { site: siteSettings } = store.state;
    const pageSettings = await this.pageContentSettings(false, publishing);
    pageSettings.globalStyles = '';
    const { pageContent, noBlocks } = await this.prepareContent(true);

    if (
      checkRedirect &&
      siteSettings.shouldRedirect &&
      siteSettings.internalRedirect !== this.settings.name
    ) {
      return this.getRedirectMarkup(siteSettings);
    }
    if (
      checkRedirect &&
      pageSettings.shouldRedirect &&
      !siteSettings.shouldRedirect
    ) {
      pageSettings.publish_folder = siteSettings.publish_folder;
      pageSettings.useFolder = siteSettings.useFolder;
      return this.getRedirectMarkup(pageSettings);
    }
    if (screenshot && noBlocks) return null;

    return pageUtils.getFullPage(
      pageContent,
      pageSettings,
      pageUtils.getDefaultPageAssets('parsed', screenshot),
      screenshot
    );
  }
};
