import Page from '@/builder/canvas/Page';
import pageUtils from '@/builder/canvas/pageUtils';
import Popup from '@/data/models/Popup';
import canvasUtils from '@groovepages/gd-canvas/utils';
import api from '@groovepages/gd-lib/api';
import { customBase64Decode, customBase64Encode } from '@pages/shared/utils';
import { MD5 } from 'crypto-js';
import { capitalize, keyBy } from 'lodash-es';
import pickBy from 'lodash-es/pickBy';
import shortid from 'shortid';
import Vue from 'vue';

let settingActive = false;
export default {
  state: {
    undoSteps: { undo: [], redo: [] },
    pagesData: {},
    pagesToDelete: [],
    pages: {},
    activePage: {},
    frame: null,
    pageLoading: true,
    configs: {},
    componentDatas: {},
    newPageProps: {},
    oldHomePage: null,
    showGrooveBranding: false,
    pageSettingsCopy: null,
  },
  getters: {
    indexPage: state => {
      const pageId = Object.keys(state.pages || {}).find(
        id => state.pages[id].settings.name === 'index'
      );
      return state.pages[pageId];
    },
    componentConfigs: state =>
      state.activePage.componentConfigs ||
      state.configs[state.activePage.pageId] ||
      {},
    componentDatas: state =>
      state.activePage.componentDatas ||
      state.componentDatas[state.activePage.pageId] ||
      {},
    activePageId: state => state.activePage && state.activePage.pageId,
    pages: ({ pages }) =>
      Object.values(pages).filter(page => page.isPageType()),
    pageIds: ({ pages }) =>
      Object.values(pages).reduce(
        (result, page) => ({
          ...result,
          [page.settings.id]: page.displayName || page.settings.name
        }),
        {}
      ),
    premiumPages: ({ pages }) =>
      Object.values(pages).filter(page => page.isPremium()),
    pagesById: ({ pages }) =>
      Object.values(pages).reduce((set, p) => ({ ...set, [p.pageId]: p }), {}),
    pageUrls: state =>
      Object.values(state.pages)
        .filter(({ type }) => !['funnel', 'webinar'].includes(type))
        .map(page => page.settings.name),
    funnelUrls: (state, { pages }) =>
      Object.values(pages)
        .filter(({ type }) => type === 'funnel')
        .reduce((set, page) => {
          if (!set[page.funnel_id]) set[page.funnel_id] = [];
          set[page.funnel_id].push(page.settings.name);
          return set;
        }, {}),
    webinarUrls: (state, { pages }) =>
      Object.values(pages)
        .filter(({ type }) => type === 'webinar')
        .reduce((set, page) => {
          if (!set[page.webinar_id]) set[page.webinar_id] = [];
          set[page.webinar_id].push(page.settings.name);
          return set;
        }, {}),
    nonGroupSlugs: (
      state,
      { pageUrls, funnelUrls, webinarUrls },
      rootState,
      rootGetters
    ) => excludeGroupId => {
      const { groupSlugs } = rootGetters;
      const funnelSlugs = Object.keys(funnelUrls || {})
        .filter(key => key !== excludeGroupId && !groupSlugs.funnels[key])
        .map(key => funnelUrls[key])
        .flat();
      const webinarSlugs = Object.keys(webinarUrls || {})
        .filter(key => key !== excludeGroupId && !groupSlugs.webinars[key])
        .map(key => webinarUrls[key])
        .flat();
      return [...pageUrls, ...funnelSlugs, ...webinarSlugs];
    },
    getExistingPageSlugs: (
      state,
      { nonGroupSlugs, funnelUrls, webinarUrls },
      rootState,
      rootGetters
    ) => ({ type, funnelId, webinarId }) => {
      if (type === 'funnel' && rootGetters.groupSlugs.funnels[funnelId])
        return funnelUrls[funnelId];
      if (type === 'webinar' && rootGetters.groupSlugs.webinars[webinarId])
        return webinarUrls[webinarId];
      return nonGroupSlugs();
    },
    popups: ({ pages }) =>
      Object.values(pages)
        .filter(({ type }) => type === 'popup')
        .map(popup => new Popup(popup))
  },
  mutations: {
    setNewPageProps(state, props) {
      state.newPageProps = props;
    },
    storeComponentConfig(state, data) {
      if (!state.activePage.componentConfigs) {
        state.activePage.componentConfigs = {};
      }
      const safeId = canvasUtils.quillSafeID(data.id);
      if (safeId) state.activePage.componentConfigs[safeId] = data.value;
    },
    storeComponentData(state, data) {
      if (!state.activePage.componentDatas)
        state.activePage.componentDatas = {};
      const safeId = canvasUtils.quillSafeID(data.id);
      if (safeId) state.activePage.componentDatas[safeId] = data.value;
    },
    setPageLoadingState(state, isLoading) {
      state.pageLoading = isLoading;
    },
    setFrame(state, frame) {
      state.frame = frame;
    },
    undoSteps(state, undoSteps) {
      state.undoSteps = undoSteps;
    },
    resetUndo(state) {
      state.undoSteps = { undo: [], redo: [] };
    },
    saveBeforeChange(state, operation) {
      operation.configs = JSON.parse(JSON.stringify(operation.configs));
      state.undoSteps.undo.push(operation);
      if (state.undoSteps.undo.length > 50) state.undoSteps.undo.shift();
      state.undoSteps = { undo: state.undoSteps.undo, redo: [] };
    },
    setPagesData(state, data) {
      state.pagesData = data.sort(
        (a, b) => Date.parse(a.created_at) - Date.parse(b.created_at)
      );
    },
    addPage(state, page) {
      if (Object.keys(state.pages).includes(page.pageId)) {
        page.pageId = shortid.generate();
      }
      Vue.set(state.pages, page.pageId, page);
      if (page.pageId === state.activePage.pageId) {
        state.activePage = page;
      }
    },
    deletePage(state, id) {
      if (!state.pages[id].new) {
        state.pagesToDelete.push(state.pages[id].settings.id);
      }
      Vue.delete(state.pages, id);
    },
    hidePage(state, id) {
      Vue.delete(state.pages, id);
    },
    removePage(state, id) {
      Vue.delete(state.pages, id);
    },
    setActivePage(state, id) {
      state.activePage = state.pages[id];
    },
    pagesDeleted(state) {
      state.pagesToDelete = [];
    },
    setHomepage(state, id) {
      const names = Object.keys(state.pages || {}).map(
        page => state.pages[page].settings.name
      );
      let newPageIndex = 1;
      while (names.indexOf(`page-${newPageIndex}`) !== -1) {
        newPageIndex += 1;
      }
      Object.keys(state.pages || {}).forEach(page => {
        if (state.pages[page].settings.name === 'index') {
          state.pages[page].settings.name = `page-${newPageIndex}`;
          state.pages[page].modified = true;
          state.oldHomePage = state.pages[page].pageId;
        }
      });
      state.pages[id].settings.name = 'index';
      state.pages[id].modified = true;
    },
    setPageGlobalBlockIds(state) {
      state.activePage.refreshGlobalBlockIds();
    },
    clearOldHomePage(state) {
      state.oldHomePage = null;
    },
    setGrooveBranding(state, val) {
      state.showGrooveBranding = val;
    },
    setPageSettingsCopy(state, val) {
      state.pageSettingsCopy = val;
    },
    setPageDraftMode(state, val) {
      state.activePage.draft_mode = val;
    }
  },
  actions: {
    getNewPageName({ state, getters }) {
      const newPageProps = state.newPageProps || {};
      const keys = Object.keys(newPageProps || {});
      const pages = keyBy(
        pickBy(state.pages, page => {
          if (!keys.length) {
            return page.type === 'page' || !page.type;
          }
          return (
            page.type === newPageProps.type &&
            page[`${page.type}_id`] === newPageProps[`${page.type}_id`]
          );
        }),
        'settings.name'
      );

      const suffix = ['popup', 'global_block'].includes(newPageProps.type)
        ? capitalize(newPageProps.type).replace(/_/g, ' ')
        : 'Page';
      const prefix = newPageProps.prefix
        ? `${newPageProps.prefix} ${suffix}`
        : suffix;
      let newPageIndex = Object.keys(pages || {}).length;
      let displayName;
      let slug;

      do {
        newPageIndex += 1;
        displayName = `${prefix} ${newPageIndex}`;
        slug = displayName.replace(/\s/g, '-').toLowerCase();
      } while (pages[slug]);

      // check duplicate slugs
      const existingSlugs = getters.getExistingPageSlugs({
        type: newPageProps.type,
        funnelId: newPageProps.funnel_id,
        webinarId: newPageProps.webinar_id
      });
      while (existingSlugs && existingSlugs.includes(slug)) {
        slug += '-copy';
      }

      return { slug, displayName };
    },
    setHomepage({ commit }, id) {
      commit('setModified', true);
      commit('setHomepage', id);
    },
    saveBeforeChange({ commit }, data) {
      commit('setModified', true);
      commit('saveBeforeChange', data);
    },
    async selectHomepage({ dispatch, state, rootState }) {
      const homePage = Object.values(state.pages).find(
        page =>
          page.settings.name === 'index' ||
          rootState.builder.mode === 'page-template'
      );
      await dispatch('setActivePage', homePage.pageId);
    },
    async setActivePage({ commit, state }, id) {
      if (state.activePage.pageId === id || settingActive) {
        return;
      }
      settingActive = true;
      commit('setPageLoadingState', true);
      commit('setActivePage', id);
      // commit('setMenuOpen', null);
      await Promise.all(
        Object.values(state.pages).map(page => page.deselect())
      );

      if (state.activePage) {
        await state.activePage.select();
        settingActive = false;
        commit('setPageLoadingState', false);
        state.activePage.refreshGlobalStyles();
        await pageUtils.refreshGlobalBlocks(state.activePage.contents);
      }
    },
    // eslint-disable-next-line complexity
    async addNewPage(
      { getters, commit, dispatch, state, rootState },
      { page, skipReload, content }
    ) {
      page.setAsNew();
      page.settings.site_id = rootState.site.settings.id;
      if (page.settings.name !== 'index') {
        commit('setModified', true);
      }
      if (content) {
        page.content = content;
      }
      commit('addPage', page);
      if (page.type !== 'megamenu' && !skipReload) {
        await dispatch('setActivePage', page.pageId);
      }
      if ((content && !skipReload) || page.type === 'megamenu') {
        state.activePage.replaceContent(content, true);
      }

      if (!page.type || ['page'].includes(page.type)) {
        if (
          page.settings.clonedFrom &&
          getters.hiddenPages.indexOf(page.settings.clonedFrom) !== -1
        ) {
          commit('addToHiddenNavigation', page.pageId);
        } else {
          commit('addToPrimaryNavigation', page.pageId);
        }
      }

      delete page.settings.clonedFrom;
      return page.pageId;
    },
    deletePage({ commit, dispatch }, id) {
      commit('setModified', true);
      commit('deletePage', id);
      dispatch('cleanNavigation');
    },
    hidePage({ commit, dispatch }, id) {
      commit('setModified', true);
      commit('hidePage', id);
      dispatch('cleanNavigation');
    },
    async savePage({ commit, rootState }, page) {
      const data = { ...page.settings };
      data.builder_settings = page.getBuilderSettings();

      if (page.content && page.content.includes('</')) {
        data.content = customBase64Encode(page.content);
        data.checksum = MD5(data.content).toString();
      }
      const uri = page.settings.id ? `update/${page.settings.id}` : `create`;
      const endpoint = `${window.gdEnv.VUE_APP_API_URL}/groovepages/pages/${uri}`;

      try {
        const { content, ...settings } = await api.post(endpoint, data);
        if (settings.site_id !== rootState.site.settings.id) {
          return;
        }
        const canvasPage = new Page(settings);
        canvasPage.content = '';
        if (content) {
          canvasPage.content = content.includes('</')
            ? content
            : customBase64Decode(content);
        }
        commit('addPage', canvasPage);
        return canvasPage;
      } catch (e) {
        if (e) {
          e.original = data;
          throw e;
        }
      }
    },
    async clonePage({ commit, rootState }, { pageId, siteId, extras }) {
      try {
        const url = `${window.gdEnv.VUE_APP_API_URL}/groovepages/pages/single-clone/${pageId}/${siteId}`;
        const settings = await api.post(url, { extras });
        if (settings.site_id !== rootState.site.settings.id) {
          return;
        }
        const canvasPage = new Page(settings);
        commit('addPage', canvasPage);
      } catch (e) {
        e.original = data;
        throw e;
      }
    },
    deleteGhostPages({ state, commit, rootState, rootGetters }) {
      const specialPages = [
        ...rootState.site.funnels,
        ...rootState.site.webinars
      ].map(page => page.id);
      const activePages = [
        ...rootGetters.hiddenPages,
        ...rootGetters.visiblePages,
        ...specialPages
      ];
      const ghostPages = Object.values(state.pages).filter(page => {
        if (['popup', 'global_block'].includes(page.type)) return false;
        const ids = [
          page.funnel_id,
          page.webinar_id,
          page.megamenuOf,
          page.pageId
        ].filter(item => Boolean(item));
        if (ids.some(id => activePages.includes(id))) return false;
        return true;
      });
      ghostPages.forEach(({ pageId }) => commit('deletePage', pageId));
    }
  }
};
