// splitted methods from 'pageUtils' at 'gp-ui/src/builder/canvas/pageUtils/index.js'

export default {
  getPopupAppendScript(content, id, popup, leavingAnimationScript, timeOut) {
    if (timeOut) {
      return `<script>
        window.addEventListener('load',function() {
          if( document.body.classList.contains('gpblsh') ){
            setTimeout(() => {
              const ${popup} = document.querySelector("[data-gp-popup-id='${id}']");
              const ${popup}_content = \`${content.replaceAll(
        'script',
        'gdscript'
      ) || ''}\`;
  
              ${popup}.querySelector(".data-gp-popup-container").innerHTML = ${popup}_content.replaceAll('gdscript','script');
  
              Array.from(${popup}.querySelector(".data-gp-popup-container").querySelectorAll("script")).forEach( oldScript => {
                const newScript = document.createElement("script");
                Array.from(oldScript.attributes).forEach( attr => newScript.setAttribute(attr.name, attr.value) );
                newScript.appendChild(document.createTextNode(oldScript.innerHTML));
                oldScript.parentNode.replaceChild(newScript, oldScript);
              });
  
              for (const popupClose of ${popup}.querySelectorAll('.gp-close-popup')) {
                popupClose.addEventListener('click', () => {
                  ${leavingAnimationScript}
                });
              }
            }, ${timeOut});
          };
        })
      </script>`;
    }
    return `<script>
      window.addEventListener('load',function() {
        if( document.body.classList.contains('gpblsh') ){
          const ${popup} = document.querySelector("[data-gp-popup-id='${id}']");

          for (const popupClose of ${popup}.querySelectorAll('.gp-close-popup')) {
            popupClose.addEventListener('click', () => {
              ${leavingAnimationScript}
            });
          }
        };
      })
      </script>`;
  },
  getPopupMarkup(
    content,
    id,
    width,
    timeOut = 0,
    leavingAnimation = null,
    buttonSize = 'small'
  ) {
    const popup = `popup${id.replaceAll('-', '_')}`;
    let closeButtonStyles = 'top:-40px;width:32px;';
    if (buttonSize === 'medium') closeButtonStyles = 'top:-50px; width:48px;';
    if (buttonSize === 'large') closeButtonStyles = 'top:-60px; width:64px;';

    let leavingAnimationScript = `popup${id.replaceAll(
      '-',
      '_'
    )}.classList.add('hidden-popup')`;

    if (leavingAnimation) {
      leavingAnimationScript = `
        ${popup}.children[0].classList.add('${leavingAnimation}');
        ${popup}.children[0].classList.add('animated');

        setTimeout(() => {
          if( ${popup}.children[0].classList )
          ${popup}.children[0].classList.remove('${leavingAnimation}');
            
          ${popup}.classList.add('hidden-popup');
        },1000);
      `;
    }

    const appendScript = this.getPopupAppendScript(
      content,
      id,
      popup,
      leavingAnimationScript,
      timeOut
    );

    return `
    <div data-gp-popup-id="${id}" style="background-color: rgba(25, 29, 58, 0.8); position: fixed; right: 0; left: 0; width:100vw; height:100vh; max-height:100vh; overflow-y: auto; display: flex; z-index: 999; align-items:center; justify-content:center; padding-left:1.5rem; padding-right:1.5rem; padding-top:3rem; padding-bottom:3rem;" class="hidden-popup">
      <div style="margin: auto; position: relative; width: ${width}px">
        <button class="gp-close-popup popup-close-size-${buttonSize ||
          'small'}" style="position: absolute; right: 0; ${closeButtonStyles}">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><title>Close</title><g stroke-linecap="square" stroke-linejoin="miter" stroke-width="1" transform="translate(0.5 0.5)" fill="#dcdcea" stroke="#dcdcea"><line fill="none" stroke="#dcdcea" stroke-miterlimit="10" x1="27" y1="5" x2="5" y2="27"></line> <line fill="none" stroke="#dcdcea" stroke-miterlimit="10" x1="27" y1="27" x2="5" y2="5"></line></g></svg>
        </button>
        <div style="position: relative" class="data-gp-popup-container" data-gp-block data-gp-component>
        ${timeOut === 0 ? content || '<div data-gp-container></div>' : ''}
        </div>
      </div>
    </div>
    ${appendScript}
    `;
  }
};
