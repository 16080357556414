// is to be used in 'Page' class at 'gp-ui/src/builder/canvas/Page/index.js'
// share methods with other 'Page' mixins (i.e pageSavingMixin, pageBlocksMixin, pageHelperMixin etc)

import Importer from '@/builder/canvas/Importer';
import pageUtils from '@/builder/canvas/pageUtils';
import embeds from '@/builder/data/embeds';
import loadSave from '@/builder/data/loadsave';
import store from '@/builder/data/store';
import legacyStyleUtils from '@/builder/legacy/legacyStyleUtils';
import api from '@groovepages/gd-lib/api';
import { customBase64Decode } from '@pages/shared/utils';

export default {
  injectAssets() {
    const loading = [];
    const injectScript = src => {
      const asset = new Promise(resolve => {
        const script = document.createElement('SCRIPT');
        script.src = src;
        script.onload = () => resolve();
        this.contents.body.appendChild(script);
      });
      loading.push(asset);
    };
    const injectStyle = href => {
      const asset = new Promise(resolve => {
        const cssLink = document.createElement('LINK');
        cssLink.href = href;
        cssLink.rel = 'stylesheet';
        cssLink.type = 'text/css';
        cssLink.onload = () => {
          resolve();
        };
        this.contents.head.appendChild(cssLink);
      });
      loading.push(asset);
    };
    const assets = pageUtils.getDefaultPageAssets();
    assets.scripts.forEach(script => injectScript(script));
    assets.styles.forEach(style => injectStyle(style));
    return Promise.all(loading);
  },

  injectStagingElement(element, blockSelector) {
    if (!this.contents) {
      this.toInject = { element, blockSelector };
      return;
    }
    const stagingContainer = this.contents.getElementById('blocks-staging');
    stagingContainer.appendChild(element);
    stagingContainer.querySelectorAll('.gp-content-merge').forEach(config => {
      config.remove();
    });
  },

  initializeContentBuilderSettings(settings) {
    if (settings.embeds) {
      const pageEmbeds = JSON.parse(customBase64Decode(settings.embeds));
      embeds.setEmbeds(pageEmbeds, this.pageId);
    }
  },

  loadContentBuilderSettings() {
    if (!this.pageWindow || !this.pageWindow.contentBuilderSettings) return;
    let contentScript = this.contents.getElementById('gp-content-settings');
    while (contentScript) {
      contentScript.remove();
      contentScript = this.contents.getElementById('gp-content-settings');
    }
    const { componentConfigs } = this.pageWindow.contentBuilderSettings;
    if (componentConfigs) {
      Object.keys(componentConfigs || {}).forEach(id => {
        store.commit('storeComponentConfig', {
          id,
          value: componentConfigs[id]
        });
      });
    }
    if (this.pageWindow.contentBuilderSettings.embeds) {
      const pageEmbeds = JSON.parse(
        customBase64Decode(this.pageWindow.contentBuilderSettings.embeds)
      );
      embeds.setEmbeds(pageEmbeds, this.pageId);
    }
    if (this.pageWindow.contentBuilderSettings.globalStyles) {
      const globalStyles =
        this.pageWindow.contentBuilderSettings.globalStyles || {};
      Object.keys(globalStyles).forEach(style => {
        if (!store.state.site.userStyles[style]) {
          store.dispatch('saveUserStyle', globalStyles[style]);
        }
      });
    }
  },

  setupPluginHooks() {
    this.loadContentBuilderSettings();
    setTimeout(() => {
      if (!this.pageWindow || !this.pageWindow.site) return;
      if (this.pageWindow.site.subscribed) {
        this.pageWindow.site.update = data => {
          Object.keys(data || {}).forEach(key => {
            this.pageWindow.site[key] = data[key];
          });
          this.pageWindow.site.subscribed.forEach(callback => {
            callback();
          });
        };
        return;
      }
      this.pageWindow.site.subscribed = [];
      this.pageWindow.site.subscribe = callback => {
        this.pageWindow.site.subscribed.push(callback);
      };
    }, 1000);
  },

  async loadContent() {
    this.contentFetched = true;
    if (this.content) return null;

    this.content = '';

    if (!this.settings.id) return null;

    const prefix =
      store.state.builder.mode !== 'standard' ? 'template-data' : 'data';
    const endpoint = `${window.gdEnv.VUE_APP_API_URL}/groovepages/pages/${prefix}/${this.settings.id}`;
    const result = await api.get(endpoint);
    let { componentConfigs } = result.builder_settings;
    if (componentConfigs) {
      componentConfigs = JSON.parse(componentConfigs);
      this.componentConfigs = componentConfigs;
      Object.keys(componentConfigs || {}).forEach(id => {
        store.commit('storeComponentConfig', {
          id,
          value: componentConfigs[id]
        });
      });
    }
    if (result.builder_settings.embeds) {
      const pageEmbeds = JSON.parse(
        customBase64Decode(result.builder_settings.embeds)
      );
      embeds.setEmbeds(pageEmbeds, this.pageId);
    }
    if (result.builder_settings.globalStyles) {
      const globalStyles =
      result.builder_settings.globalStyles || {};
      Object.keys(globalStyles).forEach(style => {
        if (!store.state.site.userStyles[style]) {
          store.dispatch('saveUserStyle', globalStyles[style]);
        }
      });
    }
    this.settings.revisions = result.revisionsList;
    if (result.content) {
      try {
        this.content = customBase64Decode(result.content).trim();
      } catch (error) {
        console.error(error);
        return this.fallbackToEarlierRevision();
      }
    }
    return null;
  },

  async setPageContent() {
    let { content } = this;
    if (!this.custom_css) this.custom_css = '';
    if (this.custom_css.startsWith('undefined')) {
      const lines = this.custom_css.split('\n');
      lines.splice(0, 1);
      this.custom_css = lines.join('\n');
    }
    if (!this.stylesProcessed && this.content) {
      const pageContent = await Importer.setInternalStyles(this.content);
      content = pageContent.content;
      this.stylesProcessed = true;
      this.custom_css += pageContent.baseStyles;
    }
    const settings = await this.pageContentSettings(true, false);
    this.contents.head.innerHTML = pageUtils.getPageHead(settings);
    this.contents.body.innerHTML = pageUtils.getPageBody(
      settings,
      content,
      [],
      true
    );
    this.contents.body.setAttribute('data-gp-style', '');
    this.contents.body.setAttribute('data-gp-component', 'Body');

    this.contents.querySelectorAll('script').forEach(script => {
      if (script.innerText.indexOf('window.isFreeUser') > -1) script.remove();
    });
  },

  setUpFrame() {
    const { frame } = store.state.pages;
    let onSetupFinished = null;
    const setup = async () => {
      await this.loadContent();
      this.contents = frame.contentDocument || frame.contentWindow.document;
      this.pageWindow = frame.contentWindow;
      await this.setPageContent();

      this.pageWindow.inBuilder = true;
      this.blocksContainer = this.contents.getElementById('blocks-container');
      legacyStyleUtils.convertLegacyIDs(this.contents);
      this.setBlockIds();
      this.addCleanerScript();
      frame.contentWindow.mergeContentSettings = configs =>
        this.mergeConfigs(configs);
      frame.contentWindow.mergeGlobalStyles = styles =>
        this.mergeGlobalStyles(styles);
      frame.removeEventListener('load', setup);
      this.removeLegacyComponentData();
      onSetupFinished();
    };
    return new Promise(resolve => {
      onSetupFinished = resolve;
      frame.addEventListener('load', setup);
      if (frame.contentWindow) {
        frame.contentWindow.location.reload();
      }
    });
  },

  mergeConfigs(configs) {
    this.contents
      .querySelectorAll('.gp-content-merge')
      .forEach(script => script.remove());
    Object.keys(configs || {}).forEach(id => {
      store.commit('storeComponentConfig', { id, value: configs[id] });
    });
  },

  mergeGlobalStyles(styles) {
    Object.keys(styles || {}).forEach(style => {
      if (!store.state.site.userStyles[style]) {
        store.dispatch('saveUserStyle', styles[style]);
      }
    });

    this.contents
      .querySelectorAll('.gp-globalstyle-merge')
      .forEach(script => script.remove());
    this.refreshGlobalStyles();
  },

  refreshSiteData() {
    if (!this.contents) return;
    store.commit('setPageLoadingState', true);
    const pageFont = this.fontFamily || store.state.site.fontFamily;
    const pageCss = pageFont ? `body { font-family: ${pageFont} }` : '';
    pageUtils.refreshSiteData(this.contents, {
      page_css: pageCss,
      custom_css: `${store.state.site.custom_css || ''}${this.custom_css || ''}`
    });
    setTimeout(() => {
      store.commit('setPageLoadingState', false);
    }, 250);
    this.refreshGlobalStyles();
  },

  refreshGlobalStyles() {
    if (!this.contents) return;
    this.contents.getElementById('global-styles').innerHTML = store.getters.globalStyleSheet;
  },

  removeLegacyComponentData() {
    this.contents
      .querySelectorAll('[data-gp-component-data]')
      .forEach(component => {
        if (component.getAttribute('data-gp-component-id'))
          store.commit('storeComponentData', {
            id: component.getAttribute('data-gp-component-id'),
            value: JSON.parse(
              JSON.stringify(component.getAttribute('data-gp-component-data'))
            )
          });
        component.removeAttribute('data-gp-component-data');
      });
  },

  addCleanerScript() {
    if (!this.contents?.getElementById('cleaner-script')) {
      const pageContainer = this.contents.getElementById('page-container');
      const cleaner = document.createElement('script');
      cleaner.setAttribute('id', 'cleaner-script');
      cleaner.innerHTML = `
          window.alert = function(msg){ console.warn('Blocked Alert: '+msg) }
        `;
      pageContainer.appendChild(cleaner);
    }
  },

  fallbackToEarlierRevision() {
    this.content = '';
    if (
      this.settings.revisions.length &&
      store.state.pages.activePage.pageId === this.pageId
    ) {
      return loadSave.restoreRevision(this.settings.revisions[0]);
    }
    return null;
  }
};
