import Page from '@/builder/canvas/Page';
import loadSave from '@/builder/data/loadsave';
import api from '@groovepages/gd-lib/api';
import { capitalize, orderBy } from 'lodash-es';
import shortid from 'shortid';
import Vue from 'vue';

export default {
  namespaced: true,
  state: {
    groups: {
      funnel: {},
      webinar: {},
      global_block: {},
      page: {},
      popup: {}
    },
    activeGroupId: {
      funnel: undefined,
      webinar: undefined
    },
    activeType: 'funnel',
    menuMode: {
      funnel: 'list',
      webinar: 'list',
      page: 'pages',
      popup: 'pages',
      global_block: 'pages'
    },
    selectedPage: undefined
  },
  getters: {
    collectionName({ activeType }) {
      const types = {
        funnel: 'funnels',
        page: undefined,
        popup: 'popups',
        webinar: 'webinars',
        global_block: 'global_blocks'
      };
      return types[activeType];
    },
    activeMenuMode({ activeType, menuMode }) {
      return menuMode[activeType];
    },
    groupsByType({ groups }) {
      return Object.keys(groups || {}).reduce(
        (types, type) => ({ ...types, [type]: Object.values(groups[type]) }),
        {}
      );
    },
    activeTypeGroups({ groups, activeType }) {
      return orderBy(Object.values(groups[activeType]), 'order');
    },
    activeGroup({ activeGroupId, activeType, groups }) {
      return activeGroupId[activeType]
        ? groups[activeType][activeGroupId[activeType]]
        : undefined;
    },
    activeGroupPages(
      { activeType, groups },
      { activeGroup },
      { pages: { pages }, site: { primaryNavigation } }
    ) {
      let group;
      if (activeType === 'page') {
        group = { pages: primaryNavigation.map(({ data: { id } }) => id) };
      } else if (activeGroup) {
        group = groups[activeType][activeGroup.id];
      }

      const groupPages = Object.values(pages)
        .filter(
          page =>
            page.type === activeType &&
            (!activeGroup || page[`${activeType}_id`] === activeGroup.id)
        )
        .map(({ pageId: id }, index) => ({
          id,
          position: group ? (group.pages || []).findIndex(p => p === id) : index
        }));
      return orderBy(groupPages, a => {
        if (a.position === -1) return groupPages.length;
        return a.position;
      });
    },
    groupPageIds(state, getters, { pages: { pages } }) {
      return Object.values(pages)
        .filter(page => page.type && page.type !== 'page')
        .map(({ pageId }) => pageId);
    }
  },
  mutations: {
    clearGroups(state) {
      state.groups[state.activeType] = {};
    },
    upsertGroup(state, { id, ...group }) {
      Vue.set(state.groups[state.activeType], id, { id, ...group });
    },
    removeGroup(state, id) {
      Vue.delete(state.groups[state.activeType], id);
    },
    setActiveGroup(state, id) {
      state.activeGroupId[state.activeType] = id;
    },
    setActiveType(state, type) {
      state.activeType = type;
    },
    setMenuMode(state, mode) {
      state.menuMode[state.activeType] = mode;
    },
    setSelectedPage(state, id) {
      state.selectedPage = id;
    }
  },
  actions: {
    loadGroups({
      state: { activeType },
      commit,
      getters,
      rootState,
      dispatch
    }) {
      const { collectionName } = getters;
      const groups =
        rootState.site[collectionName] ??
        rootState.site.settings.builder_settings[collectionName] ??
        [];
      commit('clearGroups');
      const availableGroupIds = groups.map(({ id }) => id);
      const availablePagesIds = Object.values(rootState.pages.pages)
        .filter(p => p.type === activeType)
        .map(p => p[`${activeType}_id`]);
      const missingPagesGroupFromGroups = availablePagesIds
        .filter((value, index, array) => array.indexOf(value) === index)
        .filter(id => !availableGroupIds.includes(id));
      // REVIEW: possible breaking change
      missingPagesGroupFromGroups.forEach((val, key) => {
        const order = key + availableGroupIds.length + 1;
        const counts = availablePagesIds.reduce((result, pageId) => {
          result[pageId] = (result[pageId] || 0) + 1;
          return result;
        }, {});

        commit('upsertGroup', {
          name: `${activeType.charAt(0).toUpperCase() +
            activeType.slice(1)} ${order}`,
          id: val,
          pages_count: counts[val],
          order
        });
      });

      groups.forEach(({ id, ...group }, order) => {
        const pages = Object.values(rootState.pages.pages).filter(
          p => p.type === activeType && p[`${activeType}_id`] === id
        );
        if (id)
          commit('upsertGroup', {
            ...group,
            id,
            pages_count: pages.length,
            order
          });
      });

      if (missingPagesGroupFromGroups.length) dispatch('pushToSite');
    },
    addGroup({ commit, dispatch, getters }, group) {
      const id = shortid.generate();
      commit('upsertGroup', {
        ...group,
        id,
        order: getters.activeTypeGroups.length
      });
      commit('setActiveGroup', id);
      dispatch('pushToSite');
      return id;
    },
    pushToSite({ commit, getters: { collectionName, activeTypeGroups } }) {
      commit('setModified', true, { root: true });
      const settings = {};
      settings[collectionName] = activeTypeGroups.map(
        ({ id, name, pages, favicon, slug, useSlug }) => ({
          id,
          name,
          pages,
          favicon,
          slug,
          useSlug
        })
      );
      commit('updateSiteSettings', settings, { root: true });
    },
    createBlankGroup({ dispatch, getters, state }, templateName) {
      let name = templateName;
      if (!name) {
        const existing = getters.activeTypeGroups.map(({ name }) => name);
        let count = existing.length;
        do {
          count += 1;
          name = `${capitalize(state.activeType)} ${count}`;
        } while (existing.includes(name));
      }
      return dispatch('addGroup', { name });
    },
    async createFromTemplate(
      { dispatch, state: { activeType } },
      { id, type, selectedPages, ...template }
    ) {
      const groupId = await dispatch('addGroup', {
        name: template.builder_settings.displayName || template.name
      });
      let templatePages;

      if (template.page_id) {
        templatePages = [{ id, type, ...template }];
      } else {
        templatePages = await api.get(
          `${window.gdEnv.VUE_APP_API_URL}/groovepages/pages/list/${id}`
        );
      }
      templatePages
        .filter(page =>
          selectedPages.length ? selectedPages.includes(page.id) : true
        )
        .reduce(async (previous, settings) => {
          const pages = await previous;
          const page = new Page(settings);
          const { data, content } = await page.clone([], '');
          data.builder_settings.pageId = shortid.generate();
          data.builder_settings[`${activeType}_id`] = groupId;
          data.builder_settings.type = activeType;
          data.builder_settings.templateTags = template.tags;
          data.name = `${data.name
            .replace('undefined', activeType)
            .replace(/\s/g, '-')
            .toLowerCase()}-${groupId.replace(/\s/g, '-').toLowerCase()}`;

          const result = await dispatch(
            'addNewPage',
            { page: new Page(data), content, skipReload: true },
            { root: true }
          );
          return [...pages, result];
        }, []);
    },
    // REVIEW: possible breaking change
    async cloneGroup(
      { commit, dispatch, state: { activeType }, rootState },
      { id: prevId, ...group }
    ) {
      const id = shortid.generate();
      const name = `Copy of ${group.name}`;
      if (rootState.builder.modified) {
        await loadSave.save(true, false, true);
      }
      await Promise.allSettled(
        Object.values(rootState.pages.pages)
          .filter(
            page =>
              page.type === activeType && page[`${activeType}_id`] === prevId
          )
          .map(page => {
            const extras = {
              builder_settings: {
                pageId: shortid.generate(),
                [`${activeType}_id`]: id
              }
            };
            return dispatch(
              'clonePage',
              {
                pageId: page.settings.id,
                siteId: rootState.site.settings.id,
                extras
              },
              { root: true }
            );
          })
      );
      commit('upsertGroup', { ...group, id, name });
      commit('setActiveGroup', id);
      commit('setMenuMode', 'pages');

      dispatch('pushToSite');
    },
    // REVIEW: possible breaking change
    updateGroup({ state, commit, dispatch }, group) {
      if (state.activeType === 'page') {
        const primaryNavigation = group.pages.map(id => ({
          data: { id },
          children: []
        }));
        commit('setModified', true, { root: true });
        commit('updateSiteSettings', { primaryNavigation }, { root: true });
      } else {
        commit('upsertGroup', group);
        dispatch('pushToSite');
      }
    },
    removeGroup(
      {
        commit,
        dispatch,
        state: { activeType },
        rootState: {
          pages: { pages }
        }
      },
      { id, movingPages }
    ) {
      const action = movingPages ? 'hidePage' : 'deletePage';

      Object.values(pages)
        .filter(
          page => page.type === activeType && page[`${activeType}_id`] === id
        )
        .forEach(({ pageId }) => dispatch(action, pageId, { root: true }));

      commit('removeGroup', id);
      dispatch('pushToSite');
    }
  }
};
