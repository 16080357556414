// splitted methods from 'pageUtils' at 'gp-ui/src/builder/canvas/pageUtils/index.js'

import utils from '@/builder/canvas/canvasUtils';
import styleWriter from '@groovepages/gd-canvas/style-editor/stylewriter';
import canvasUtils from '@groovepages/gd-canvas/utils';
import shortid from 'shortid';

export default {
  getStylesheetForGlobals(style, styles) {
    style = canvasUtils.quillSafeID(style);
    let sheet = '';
    const backgrounds = {};
    Object.keys(styles || {}).forEach(screen => {
      backgrounds[screen] = {};

      Object.keys(styles[screen] || {}).forEach(stateVariant => {
        backgrounds[screen][stateVariant] = {};
        backgrounds[screen][stateVariant].backgroundColor =
          styles[screen][stateVariant].backgroundColor;
        delete styles[screen][stateVariant].backgroundColor;
      });
    });
    sheet += styleWriter.getStylesheet(styles, `.global-style-${style}`);
    sheet += styleWriter.getStylesheet(
      backgrounds,
      `.global-style-${style}:not([data-gp-component="block"]), .global-style-${style} [data-section-overlay]`
    );
    return sheet;
  },
  replaceOldBlockIds(content) {
    const oldIds = Array.from(content.querySelectorAll('[data-gp-block]')).map(
      el => {
        el.removeAttribute('data-gp-block');
        return canvasUtils.getComponentIdFromClass(el);
      }
    );
    let contentWithNewIds = content.innerHTML;
    oldIds.forEach(oldId => {
      const newId = shortid.generate();
      contentWithNewIds = contentWithNewIds.replace(
        new RegExp(`${oldId}`, 'gm'),
        newId
      );
    });
    return contentWithNewIds;
  },
  async refreshGlobalBlocks(content, forceReplace) {
    const elements = content.querySelectorAll(
      '[data-gp-block-type="global_block"]'
    );
    const blocksContainer =
      content.querySelector('.blocks-container') || content;

    const sections = Array.from(elements).map(async element => {
      const type = element.dataset.gpBlockType;
      const data = JSON.parse(element.dataset.gpBlockData);
      const id = element.dataset.gpBlockId;
      const { markup, revision } = await utils.getBlock({ id, type });
      if (!markup || (!forceReplace && revision === data.revision)) {
        return;
      }
      try {
        const base = document.createElement('div');

        // TODO: try better replace
        base.innerHTML = markup
          .replace(/data-gp-style=""/gm, '')
          .replace(/data-gp-container=""/gm, '')
          .replace(/data-gp-text=""/gm, '')
          .replace(/\[data-gp-text]/gm, '')
          .replace(/data-gp-dnd=""/gm, '')
          .replace(/data-gp-component="navbar\S*"/gm, 'data-navbar')
          .replace(/data-gp-component-id="\S*"/gm, '')
          .replace(/data-gp-component="(?!(pattern-background|video-background)")\S*"/gm, '')
          .replace(/\[data-gp-component](?!(pattern-background|video-background))/gm, '')
          .replace(/(?<=<(\/?))section/gm, 'div');

        // const el = base.querySelector(element.tagName);

        base.innerHTML = this.replaceOldBlockIds(base);

        if (blocksContainer) {
          this.moveStyles(base, blocksContainer);
        }

        element.setAttribute('data-gp-disable-styles', '');
        element.setAttribute(
          'data-gp-disable-buttons',
          JSON.stringify(['save', 'grid', 'spacer', 'clipboard', 'source'])
        );
        element.innerHTML = base.innerHTML;
      } catch (e) {
        console.error(e);
      }
    });
    await Promise.all(sections);
    return content;
  },
  moveStyles(source, target) {
    const styles = Array.from(source.querySelectorAll('style')).sort((a, b) =>
      a.className < b.className ? -1 : 1
    );
    styles.forEach(style => {
      const selector = style.className.split(' ').pop();
      if (selector) {
        target
          .querySelectorAll(`.${selector}`)
          .forEach(styleEl => styleEl.remove());
      }
      target.prepend(style.cloneNode(true));
      style.remove();
    });
  }
};
