export const customBase64Encode = function(str) {
  return btoa(encodeURIComponent(str));
};

export const customBase64Decode = function (str) {
  try {
    return decodeURIComponent(atob(str));
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const hasValues = (object, matcher) => {
  const checkObject = toCheck => {
    let objectHasValues = false;
    Object.keys(toCheck).forEach(key => {
      if (
        toCheck[key] &&
        typeof toCheck[key] !== 'object' &&
        toCheck[key] !== matcher
      ) {
        objectHasValues = true;
      }
      if (objectHasValues) return;
      if (toCheck[key] && typeof toCheck[key] === 'object') {
        objectHasValues = checkObject(toCheck[key]);
      }
    });
    return objectHasValues;
  };
  if (!object) return false;
  if (typeof object === 'object') return checkObject(object);
  return object !== matcher;
};

export const getMergeScript = (content, selector, allConfigs) => {
  const configs = {};
  content
    .querySelectorAll(`${selector} [data-gp-component-id]`)
    .forEach(component => {
      const id = component.getAttribute('data-gp-component-id');
      configs[id] = allConfigs[id];
    });
  return `<script class="gp-content-merge">
       mergeContentSettings(${JSON.stringify(configs)})
    </script>`;
};
