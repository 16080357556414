// is to be used in 'Page' class at 'gp-ui/src/builder/canvas/Page/index.js'
// share methods with other 'Page' mixins (i.e pageSavingMixin, pageLoadingMixin, pageBlocksMixin etc)

export default {
  canMove() {
    let movable = true;
    if (this.type === 'global_block') movable = !this.blockInUse();
    return movable;
  },

  canDelete() {
    let deletable = true;
    if (this.type === 'global_block') deletable = !this.blockInUse();
    return deletable;
  },

  isEmpty() {
    if (!this.contents) return true;
    if (this.blocksContainer.children.length) return false;
    return true;
  },

  setAsNew() {
    this.new = true;
    return this;
  },

  setAsOld() {
    this.new = false;
    return this;
  },

  isPageType() {
    return !['popup', 'megamenu', 'global_block'].includes(this.type);
  },

  isPremium() {
    return this.templateTags && !this.templateTags.includes('free');
  },

  getElement(selector) {
    const elements = this.contents.querySelectorAll(selector);
    return elements.length ? elements[0] : null;
  },

  getRedirectMarkup(settings) {
    const internalRedirectUrl =
      settings.useFolder && settings.publish_folder
        ? `/${settings.publish_folder}/${settings.internalRedirect}`
        : settings.internalRedirect;
    const redirectURI =
      settings.redirectType === 'internal'
        ? internalRedirectUrl
        : settings.externalRedirect;
    return `<!DOCTYPE html>
    <html lang="en">
      <head>
        <meta charset="utf-8" />
        <title>${settings.title || ''}</title>
        <noscript>
          <meta http-equiv="refresh" content="0;URL='${redirectURI}'" />
        </noscript>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta property="og:title" content="${settings.og_title || ''}" />
        <meta property="og:description" content="${settings.og_description ||
          ''}" />
        <meta property="og:image" content="${settings.og_image}" />
        <meta name="description" content="${settings.description || ''}">
        <meta name="keywords" content="${settings.tags || ''}" />
      </head>
      <body>
        <p>Redirecting...</p>

        <script>
          (function () {
            const redirectURL = '${redirectURI}';
            const queryStr = window.location.search;
            setTimeout(() => {
              window.location.replace(redirectURL + queryStr);
            }, 1000);
          })();
        </script>
      </body>
    </html>
    `;
  },

  getWebhooks() {
    if (!this.contents) return null;
    let totalWebhooks = 0;
    this.contents
      .querySelectorAll('[data-gp-component="form-container"]')
      .forEach(form => {
        if (form.getAttribute('method') === 'post') totalWebhooks += 1;
      });
    return totalWebhooks;
  },

  getEmbeds() {
    if (!this.contents) return null;
    return this.contents.querySelectorAll('[data-gp-component="embed"]').length;
  },

  getIntegrations() {
    if (!this.contents) return null;
    return this.contents.querySelectorAll('[data-gp-integration]').length;
  },

  getPageLinks(content) {
    if (!content) content = this.blocksContainer?.innerHTML || '';
    return content.match(/(?<=setPageUrl\(button,").+(?=")/gm) || [];
  },

  getPopupLinks(content) {
    if (!content) content = this.blocksContainer?.innerHTML || '';
    return content.match(/(?<=\[data-gp-popup-id=").+(?=")/gm) || [];
  },

  updateOnPageSiteSettings(settings) {
    try {
      if (this.pageWindow?.site?.update) {
        this.pageWindow.site.update(settings);
      }
    } catch (e) {
      console.error(e);
    }
  }
};
