import defu from 'defu';
import styleProperties from './styleProperties';
import styleFunctions from './styleFunctions';

export default class ComponentStyles {
  constructor(element, savedConfig) {
    this.savedConfig = savedConfig;
    this.element = element;
    this.styleProperties = styleProperties();
    this.getProperties(savedConfig);
  }

  refreshProperties() {
    this.styleProperties = styleProperties();
    this.getProperties(this.savedConfig);
  }

  getProperties(savedConfig) {
    for (const section in this.styleProperties) {
      for (const propertyName in this.styleProperties[section]) {
        const property = this.styleProperties[section][propertyName];
        const saved =
          savedConfig &&
          savedConfig[section] &&
          savedConfig[section][propertyName];
        property.section = section;
        property.propertyName = propertyName;
        if (!property.getter) {
          styleFunctions.getTailwindProperty(property, this.element);
        } else property.getter(property, this.element, saved);
      }
      this[section] = this.styleProperties[section];
    }
  }

  setProperty(data) {
    try {
      if (data.setter) return data.setter(data, this.element);
      for (const screen in data.selected) {
        this.setClasses(data, screen);
      }
    } catch (error) {
      console.log(error);
    }
  }

  setClasses(data, screen) {
    const classesToAdd = this.getClassesToAdd(data, screen);
    classesToAdd.forEach(className => {
      this.element.classList.add(className);
    });
  }

  setUserStyle(data) {
    const styles = JSON.parse(data.styles);
    let styleMaps = [];
    Object.keys(styles).forEach(section => {
      styleMaps = styleMaps.concat(this.setSection(section, styles[section]));
    });
    return styleMaps;
  }

  setSection(section, data) {
    const styleMaps = [];
    console.log(section,this[section],this);
    Object.keys(data).forEach(property => {
      if(this[section]) {
        this[section][property].selected = data[property];
        const styleMap = this.setProperty(this[section][property]);
        if (styleMap) styleMaps.push(styleMap);
      }
    });
    return styleMaps;
  }

  getAppliedStyles() {
    const styles = {};
    Object.keys(this).forEach(section => {
      if (this[section] && typeof this[section] === 'object') {
        styles[section] = this.parseStyleSection(this[section]);
      }
    });
    Object.keys(styles).forEach(section => {
      if (!Object.keys(styles[section]).length) delete styles[section];
    });

    return styles;
  }

  clearOverridenStyles(styles, componentId) {
    for (const section in styles) {
      for (const property in styles[section]) {
        if (
          this[section] &&
          this[section][property] &&
          this[section][property].styleProperties
        ) {
          this.removePropertyClasses(this[section][property]);
        }
        this.element.ownerDocument
          .querySelectorAll(`.style-${componentId}-${section}-${property}`)
          .forEach(sheet => {
            sheet.remove();
          });
      }
    }
  }

  mergeOverriddenStyles(styles) {
    for (const section in styles) {
      for (const property in styles[section]) {
        if (this[section] && this[section][property]) {
          this[section][property].selected = defu(
            this[section][property].selected,
            styles[section][property]
          );
        }
      }
    }
  }

  removePropertyClasses(property) {
    for (const screen in property.selected) {
      const screenPrefix = screen === 'all' ? '' : `${screen}:`;
      property.styleProperties.forEach(styleClass => {
        this.element.classList.remove(screenPrefix + styleClass.class);
      });
    }
  }

  parseStyleSection(section) {
    const properties = {};
    const objectIsEmpty = object => {
      let empty = true;
      Object.keys(object).forEach(variant => {
        if (object[variant] && typeof object[variant] === 'object') {
          Object.keys(object[variant]).forEach(subVariant => {
            if (typeof object[variant][subVariant] !== 'object') empty = false;
            else if (
              object[variant][subVariant] &&
              typeof object[variant][subVariant] === 'object' &&
              Object.keys(object[variant][subVariant]).length
            ) {
              empty = false;
            }
          });
        }
      });
      return empty;
    };
    Object.keys(section).forEach(property => {
      const {selected} = section[property];
      if (selected && typeof selected !== 'object') {
        properties[property] = selected;
      } else if (
        selected &&
        typeof selected === 'object' &&
        !objectIsEmpty(selected)
      ) {
        properties[property] = selected;
      }
    });
    return properties;
  }

  getClassesToAdd(data, screen) {
    const screenPrefix = screen === 'all' ? '' : `${screen}:`;
    const classesToAdd = [];
    for (const stateVariant of data.stateVariants) {
      // eslint-disable-next-line no-loop-func
      data.styleProperties.forEach(styleClass => {
        let selected = data.selected[screen][stateVariant];
        if (!Array.isArray(selected)) selected = [selected];
        selected.forEach(selectedClass => {
          this.element.classList.remove(screenPrefix + styleClass.class);
          const matchesSelected =
            styleClass.option === selectedClass ||
            styleClass.class === selectedClass;
          const isBaseStateClass =
            stateVariant === 'responsive' &&
            styleClass.class.indexOf(':') === -1;
          const isVariantClass = styleClass.class.indexOf(stateVariant) !== -1;
          if (matchesSelected && (isBaseStateClass || isVariantClass)) {
            classesToAdd.push(screenPrefix + styleClass.class);
          }
        });
      });
    }
    return classesToAdd;
  }
}
