import { getBreakpointText } from '@pages/builder/canvas/canvasUtils';
import Vue from 'vue';

let tag = null;
let mode = 'standard';
let type = window.location.pathname.split('/');
type = type[type.length - 2];
if (type === 'template') {
  mode = 'site-template';
} else if (type === 'site-template') {
  tag = 'site';
  mode = 'site-template';
} else if (type === 'funnel-template') {
  tag = 'funnel';
  mode = 'site-template';
} else if (type === 'webinar-template') {
  tag = 'webinar';
  mode = 'site-template';
} else if (type === 'popup-template') {
  tag = 'popup';
  mode = 'page-template';
} else if (type === 'page-template') {
  tag = 'page';
  mode = 'page-template';
}
export default {
  state: {
    tag,
    mode,
    tags: [],
    modified: false,
    redirect: '',
    elements: {},
    templates: {},
    menuOpen: null,
    publishing: false,
    publishStatus: null,
    saving: false,
    viewmode: 'xl',
    stateVariant: 'none',
    singleScreenOverride: false,
    cascadeSpace: true,
    srcEditEnabled: false,
    componentTypes: {},
    componentCategories: {},
    pluginSettingsModified: false,
    applyToAll: false,
    alertMessages: [],
    isSettingsDirty: false,
    blocks: {},
    sidebarVisibility: true,
    miniStyleEditor: JSON.parse(localStorage.getItem('gp-mini-style-editor')),
    permissionError: null,
    componentHasConfig: false
  },
  mutations: {
    setComponentTypes(state, types) {
      state.componentTypes = types;
    },
    setComponentCategories(state, categories) {
      state.componentCategories = categories;
    },
    setTags(state, tags) {
      state.tags = tags;
    },
    setSrcEdit(state, enabled) {
      state.srcEditEnabled = enabled;
    },
    setScreensize(state, size) {
      state.viewmode = size;
    },
    setStateVariant(state, variant) {
      state.stateVariant = variant;
    },
    setStyleOverride(state, active) {
      state.singleScreenOverride = active;
    },
    setCascadeSpace(state, active) {
      state.cascadeSpace = active;
    },
    setBlocksData(state, blocks) {
      state.blocks = {
        items: blocks,
        types: blocks.map(({ tag }) => {
          let rank = 99;
          if( tag === "Modern" ) rank = 2;
          else if( tag === "designed" ) rank = 1;
          return `${rank} - ${tag}`;
        }).filter((value, index, array) => array.indexOf(value) === index).sort((a, b) => {
          const rankA = a.split(' - ')[0];
          const rankB = b.split(' - ')[0];

          return rankA - rankB;
        }).map((tag) => {
          return tag.split(' - ')[1];
        })
      };
    },
    setTemplates(state, templates) {
      state.templates = templates;
    },
    setMenuOpen(state, menu) {
      state.menuOpen = menu;
    },
    publishingState(state, isPublishing) {
      state.publishing = isPublishing;
    },
    publishStatus(state, data) {
      state.publishStatus = data;
    },
    savingState(state, isSaving) {
      state.saving = isSaving;
    },
    addAlertMessage(state, notification) {
      if (
        state.alertMessages.filter(alert => alert.title === notification.title)
          .length === 0
      )
        state.alertMessages.push(notification);
    },
    removeAlertMessage(state, title) {
      state.alertMessages = state.alertMessages.filter(
        alert => alert.title !== title
      );
    },
    addComponent(state, component) {
      state.componentTypes = {
        ...state.componentTypes,
        [component.name]: component
      };
    },
    deleteComponent(state, component) {
      Vue.delete(state.componentTypes, component.name);
    },
    addBlock(state, block) {
      const index = state.blocks.items.findIndex(b => b.id === block.id);
      if (index > -1) {
        state.blocks.items.splice(index, 1, block);
      } else {
        state.blocks.items.push(block);
      }
    },
    deleteBlock(state, id) {
      state.blocks.items = state.blocks.items.filter(block => block.id !== id);
    },
    applyToAll(state, value) {
      state.applyToAll = value;
    },
    setSettingsDirty(state, value) {
      state.isSettingsDirty = value;
    },
    setSidebarVisibility(state, value) {
      state.sidebarVisibility = value;
    },
    toggleMiniStyleEditor(state) {
      localStorage.setItem('gp-mini-style-editor', !state.miniStyleEditor);
      state.miniStyleEditor = !state.miniStyleEditor;
    },
    setPermissionError(state, value) {
      state.permissionError = value;
    },
    setComponentHasConfig(state, value) {
      state.componentHasConfig = value;
    }
  },
  actions: {
    addAlertMessage({ commit }, notification) {
      commit('addAlertMessage', notification);

      setTimeout(() => {
        commit('removeAlertMessage', notification.title);
      }, 5000);
    }
  },
  getters: {
    screenBreakpoint(state) {
      return getBreakpointText(state.viewmode);
    },
    componentsByNames({ componentTypes }) {
      return Object.keys(componentTypes || {}).reduce((collection, id) => {
        const component = componentTypes[id];
        return { ...collection, [component.name]: component };
      }, {});
    },
    applyToAll(state) {
      return state.applyToAll;
    }
  }
};
