import AlignCenter from '@fortawesome/fontawesome-free/svgs/solid/align-center.svg';
import AlignJustify from '@fortawesome/fontawesome-free/svgs/solid/align-justify.svg';
import AlignLeft from '@fortawesome/fontawesome-free/svgs/solid/align-left.svg';
import AlignRight from '@fortawesome/fontawesome-free/svgs/solid/align-right.svg';
import Italic from '@fortawesome/fontawesome-free/svgs/solid/italic.svg';
import Underline from '@fortawesome/fontawesome-free/svgs/solid/underline.svg';
import BorderDashed from '@groovepages/gd-ui-kit/assets/border-dashed.svg';
import BorderDotted from '@groovepages/gd-ui-kit/assets/border-dotted.svg';
import BorderSolid from '@groovepages/gd-ui-kit/assets/border-solid.svg';
import CloseIcon from '@groovepages/gd-ui-kit/assets/close-icon.svg';
import HAlignCenter from '@groovepages/gd-ui-kit/assets/flex/hor_align_center.svg';
import HAlignEnd from '@groovepages/gd-ui-kit/assets/flex/hor_align_end.svg';
import HAlignStart from '@groovepages/gd-ui-kit/assets/flex/hor_align_start.svg';
import HAlignStretch from '@groovepages/gd-ui-kit/assets/flex/hor_align_stretch.svg';
import HJustifyAround from '@groovepages/gd-ui-kit/assets/flex/hor_justify_around.svg';
import HJustifyBetween from '@groovepages/gd-ui-kit/assets/flex/hor_justify_between.svg';
import HJustifyCenter from '@groovepages/gd-ui-kit/assets/flex/hor_justify_center.svg';
import HJustifyEnd from '@groovepages/gd-ui-kit/assets/flex/hor_justify_end.svg';
import HJustifyStart from '@groovepages/gd-ui-kit/assets/flex/hor_justify_start.svg';
import VAlignCenter from '@groovepages/gd-ui-kit/assets/flex/vert_align_center.svg';
import VAlignEnd from '@groovepages/gd-ui-kit/assets/flex/vert_align_end.svg';
import VAlignStart from '@groovepages/gd-ui-kit/assets/flex/vert_align_start.svg';
import VAlignStretch from '@groovepages/gd-ui-kit/assets/flex/vert_align_stretch.svg';
import VJustifyAround from '@groovepages/gd-ui-kit/assets/flex/vert_justify_around.svg';
import VJustifyBetween from '@groovepages/gd-ui-kit/assets/flex/vert_justify_between.svg';
import VJustifyCenter from '@groovepages/gd-ui-kit/assets/flex/vert_justify_center.svg';
import VJustifyEnd from '@groovepages/gd-ui-kit/assets/flex/vert_justify_end.svg';
import VJustifyStart from '@groovepages/gd-ui-kit/assets/flex/vert_justify_start.svg';
import LineThrough from '@groovepages/gd-ui-kit/assets/lineThrough.svg';
import Overline from '@groovepages/gd-ui-kit/assets/overline.svg';
import tailwindConfig from '@groovepages/gp-page-ui-kit/tailwind';

let marginValues = [...new Set(Object.values(tailwindConfig.margin))];
marginValues = [
  ...marginValues
    .filter(value => value !== 'auto' && value !== '0')
    .map(value => `-${value}`),
  ...marginValues
];

const paddingValues = [...new Set(Object.values(tailwindConfig.padding))];

const borderWidths = Object.values(tailwindConfig.borderWidths).sort();

const fontWeights = {
  'Parent default': 'null',
  Hairline: 100,
  Light: 300,
  Regular: 400,
  Semibold: 600,
  Bold: 700,
  Black: 900
};
const letterSpacings = {
  'Parent default': 'null',
  Tight: '-0.05em',
  Normal: '0',
  Wide: '0.05em'
};
const lineHeights = {
  'Parent Default': 'inherit',
  None: '1',
  Tight: '1.25',
  Normal: '1.5',
  Loose: '2'
};

const strokeWidths = {
  'Parent default': 'null',
  None: '0',
  '1 px': '1px',
  '2 px': '2px',
  '3 px': '3px',
  '4 px': '4px',
  '5 px': '5px'
};
export default {
  breakpoints: {
    xs: '400px',
    sm: '640px',
    md: '768px',
    lg: '992px',
    xl: '1200px'
  },
  states: ['Hover', 'Active', 'Focus'],
  sections: {
    text: {
      fontSize: Object.values(tailwindConfig.textSizes),
      fontWeight: fontWeights,
      color: tailwindConfig.colors,
      textAlign: {
        left: AlignLeft,
        center: AlignCenter,
        right: AlignRight,
        justify: AlignJustify
      },
      letterSpacing: letterSpacings,
      lineHeight: lineHeights,
      strokeWidth: strokeWidths,
      styleDecoration: {
        italic: Italic,
        overline: Overline,
        underline: Underline,
        lineThrough: LineThrough
      }
    },
    border: {
      borderTopLeftRadius: [
        '0',
        '0.125rem',
        '0.25rem',
        '0.35rem',
        '0.5rem',
        '0.75rem',
        '9999px'
      ],
      borderTopRightRadius: [
        '0',
        ...Object.values(tailwindConfig.borderRadius).sort()
      ],
      borderBottomLeftRadius: [
        '0',
        ...Object.values(tailwindConfig.borderRadius).sort()
      ],
      borderBottomRightRadius: [
        '0',
        ...Object.values(tailwindConfig.borderRadius).sort()
      ],
      borderLeftStyle: {
        none: CloseIcon,
        solid: BorderSolid,
        dashed: BorderDashed,
        dotted: BorderDotted
      },
      borderRightStyle: {},
      borderTopStyle: {},
      borderBottomStyle: {},
      borderLeftWidth: borderWidths,
      borderRightWidth: {},
      borderTopWidth: {},
      borderBottomWidth: {},
      borderLeftColor: tailwindConfig.colors,
      borderRightColor: {},
      borderTopColor: {},
      borderBottomColor: {}
    },
    background: {
      backgroundColor: tailwindConfig.colors,
      backgroundImage: '',
      backgroundSize: {
        auto: 'Auto',
        cover: 'Cover',
        contain: 'Contain'
      },
      backgroundRepeat: ['repeat', 'no-repeat', 'repeat-x', 'repeat-y','round', 'space'],
      backgroundPosition: {
        center: '50%',
        bottom: 'bottom',
        top: 'top',
        left: '0',
        'left-bottom': '0 100%',
        'left-top': '0 0',
        right: '100%',
        'right-bottom': '100% 100%',
        'right-top': '100% 0'
      },
      backgroundAttachment: '',
      backgroundPattern: {
        Wavy: 'Wavy',
        Rhombus: 'Rhombus',
        ZigZag: 'ZigZag',
        Moon: 'Moon',
        Circles: 'Circles',
        Diagonal: 'Diagonal',
        Diagonalv2: 'Diagonalv2',
        Diagonalv3: 'Diagonalv3',
        Paper: 'Paper',
        Isometric: 'Isometric',
        Polka: 'Polka',
        Polkav2: 'Polkav2',
        Lines: 'Lines',
        Linesv2: 'Linesv2',
        Linesv3: 'Linesv3',
        Linesv4: 'Linesv4',
        Triangle: 'Trianglev2',
        Rectangle: 'Rectangle'
      }
    },
    opacity: {
      opacity: [
        '1',
        '0.9',
        '0.8',
        '0.7',
        '0.6',
        '0.5',
        '0.4',
        '0.3',
        '0.2',
        '0.1',
        '0'
      ]
    },
    shadow: {
      boxShadow: {
        'Page default': 'null',
        None: 'none',
        Custom: 'custom',
        Default: 'rgba(0, 0, 0, 0.1) 0px 2px 4px 0px',
        Md:
          'rgba(0, 0, 0, 0.12) 0px 4px 8px 0px, rgba(0, 0, 0, 0.08) 0px 2px 4px 0px',
        Lg:
          'rgba(0, 0, 0, 0.11) 0px 15px 30px 0px, rgba(0, 0, 0, 0.08) 0px 5px 15px 0px',
        Xl: 'rgba(0, 0, 0, 0.21) 0px 2px 10px 3px',
        Inner: 'rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset',
        Emboss: 'rgba(0, 0, 0, 0.2) 0px -3px 0px inset'
      },
      color: tailwindConfig.colors
    },
    spacing: {
      marginLeft: marginValues,
      marginRight: marginValues,
      marginTop: marginValues,
      marginBottom: marginValues,
      paddingLeft: paddingValues,
      paddingRight: paddingValues,
      paddingTop: paddingValues,
      paddingBottom: paddingValues
    },
    sizing: {
      width: {
        'Parent default': 'null',
        Auto: 'auto',
        'Fixed width (px)': [...Array(1201)].map((_, i) => `${i}px`),
        'Relative to parent (%)': [...Array(101)].map((_, i) => `${i}%`)
      },
      height: {
        Auto: 'auto',
        'Full screen (100vh)': '100vh',
        'Fixed height (px)': [...Array(1201)].map((_, i) => `${i}px`),
        'Relative to parent (%)': [...Array(101)].map((_, i) => `${i}%`)
      },
      minWidth: {
        'Parent default': 'null',
        'Static (px)': [...Array(1201)].map((_, i) => `${i}px`),
        'Percentage (%)': [...Array(101)].map((_, i) => `${i}%`)
      },
      maxWidth: {
        'Parent default': 'null',
        'Static (px)': [...Array(1201)].map((_, i) => `${i}px`),
        'Percentage (%)': [...Array(101)].map((_, i) => `${i}%`)
      }
    },
    layout: {
      flexDirection: {
        row: 'Horizontal',
        column: 'Vertical'
      },
      justifyContent: {
        row: {
          'flex-start': HJustifyStart,
          center: HJustifyCenter,
          'flex-end': HJustifyEnd,
          'space-between': HJustifyBetween,
          'space-around': HJustifyAround
        },
        column: {
          'flex-start': VJustifyStart,
          center: VJustifyCenter,
          'flex-end': VJustifyEnd,
          'space-between': VJustifyBetween,
          'space-around': VJustifyAround
        }
      },
      alignItems: {
        row: {
          'flex-start': HAlignStart,
          center: HAlignCenter,
          'flex-end': HAlignEnd,
          stretch: HAlignStretch
        },
        column: {
          'flex-start': VAlignStart,
          center: VAlignCenter,
          'flex-end': VAlignEnd,
          stretch: VAlignStretch
        }
      },
      display: [
        'block',
        'inline-block',
        'inline',
        'table',
        'table-row',
        'table-cell',
        'none',
        'flex',
        'inline-flex'
      ]
    }
  }
};
