import StyleReader from '@groovepages/gd-canvas/style-editor/StyleReader';
import styleWriter from '@groovepages/gd-canvas/style-editor/stylewriter';
import canvasUtils from '@groovepages/gd-canvas/utils';
import tailwindConfig from '@groovepages/gp-page-ui-kit/tailwind';
import styleProperties from '@pages/builder/canvas/styleProperties';
import builderUtils from '../canvas/canvasUtils';
import ComponentStyles from './ComponentStyles';

export default {
  convertLegacyIDs(content) {
    if( !content ) return;
    content
      .querySelectorAll('[class*="gp-component-id-"]')
      .forEach(component => {
        [...component.classList].forEach(className => {
          if (className.includes('gp-component-id-')) {
            const id = className.replace('gp-component-id-', '');
            if (component.getAttribute('data-gp-component-id')) {
              component.setAttribute(
                'data-gp-component-id',
                canvasUtils.quillSafeID(id)
              );
            }
            if (id === canvasUtils.quillSafeID(id)) return;
            component.classList.remove(className);
            component.classList.add(
              `gp-component-id-${  canvasUtils.quillSafeID(id)}`
            );
            this.fixComponentStyles(content, id);
          }
        });
      });
    content.querySelectorAll('[class*="global-style-"]').forEach(component => {
      [...component.classList].forEach(className => {
        if (className.includes('global-style')) {
          const id = className.replace('global-style-', '');
          if (id === canvasUtils.quillSafeID(id)) return;
          component.classList.remove(className);
          component.classList.add(
            `global-style-${  canvasUtils.quillSafeID(id)}`
          );
        }
      });
    });
    content
      .querySelectorAll(
        '[data-gp-text] .gp-component, [data-gp-text] [data-gp-component-id]'
      )
      .forEach(brokenTextNode => {
        brokenTextNode.classList.remove('gp-component');
        brokenTextNode.removeAttribute('data-gp-component-id');
        brokenTextNode.removeAttribute('data-gp-component');
      });
  },
  fixComponentStyles(content, id) {
    if( !content ) return;
    content.querySelectorAll('style').forEach(stylesheet => {
      if (stylesheet.getAttribute('class')) {
        const classAttribute = stylesheet
          .getAttribute('class')
          .split(id)
          .join(canvasUtils.quillSafeID(id));
        stylesheet.setAttribute('class', classAttribute);
      }
      if (stylesheet.getAttribute('data-gp-styled-element')) {
        const styledAttribute = stylesheet
          .getAttribute('data-gp-styled-element')
          .split(id)
          .join(canvasUtils.quillSafeID(id));
        stylesheet.setAttribute('data-gp-styled-element', styledAttribute);
      }
      stylesheet.innerHTML = stylesheet.innerHTML
        .split(id)
        .join(canvasUtils.quillSafeID(id));
    });
  },
  convertTextChild(page, element) {
    const refNode = element.cloneNode(true);
    const id = element.getAttribute('data-gp-component-id');
    const child = element.querySelector(`.gp-component-id-${id}`);
    if (!child) return;
    [...refNode.classList].forEach(className => {
      if (className.includes('global-style-')) {
        child.classList.add(className);
        element.classList.remove(className);
      }
    });
    const refChild = refNode.querySelector(`.gp-component-id-${id}`);
    let properties = {};
    for (const section in styleProperties.sections) {
      properties = { ...properties, ...styleProperties.sections[section] };
    }
    const styleReader = new StyleReader(
      styleProperties.breakpoints,
      styleProperties.states,
      properties
    );
    const stylesheets = builderUtils.getComponentStylesheets(id, page);
    const styles = styleReader.getStyles(refChild, stylesheets);
    const newId = canvasUtils.uniqueId();
    [...child.classList].forEach(className => {
      if (className.includes('gp-component')) {
        child.classList.remove(className);
      }
    });
    child.classList.add(`gp-component-id-${newId}`);
    const content = styleWriter.getStylesheet(
      styles,
      `[data-gp-text] .gp-component-id-${newId}`
    );
    const stylesheet = document.createElement('style');
    stylesheet.innerHTML = content;
    stylesheet.classList.add(`style-${newId}`);
    stylesheet.setAttribute('data-gp-styled-element', newId);
    page.getElementById('blocks-container').prepend(stylesheet);
  },
  convertLegacyGlobalStyle(page, style) {
    let properties = {};
    for (const section in styleProperties.sections) {
      properties = { ...properties, ...styleProperties.sections[section] };
    }
    const styleReader = new StyleReader(
      styleProperties.breakpoints,
      styleProperties.states,
      properties
    );
    const placeholder = document.createElement('div');
    page.contents.body.appendChild(placeholder);
    const componentStyles = new ComponentStyles(placeholder);
    placeholder.classList.add(`global-style-convert`);
    const stylesheet = document.createElement('style');
    const styleMaps = componentStyles.setUserStyle(style);
    const appliedProperties = this.getAppliedClassProperties(placeholder);
    stylesheet.innerHTML = this.mapVariantsToStylesheet(
      appliedProperties,
      `.global-style-convert`
    );
    styleMaps.forEach(styleMap => {
      stylesheet.innerHTML += this.mapVariantsToStylesheet(
        styleMap,
        `.global-style-convert`
      );
    });
    page.contents.head.appendChild(stylesheet);
    const stylesheets = [...page.contents.styleSheets].filter(
      sheet =>
        !sheet.href || sheet.href.includes(window.gdEnv.VUE_APP_FRONTEND_URL)
    );
    const convertedProperties = styleReader.getStyles(placeholder, stylesheets);
    placeholder.remove();
    stylesheet.remove();
    return convertedProperties;
  },
  getPropertiesFromcssClasses(el, classes) {
    const properties = {};
    tailwindConfig.stateVariants.forEach(variant => {
      properties[variant] = {};
    });
    const appliedClasses = [];
    for (let i = 0; i < classes.length; i++) {
      let selectorText = classes[i].selectorText
        .replace('.', '')
        .replace(new RegExp('\\\\', 'g'), '');
      tailwindConfig.stateVariants.forEach(variant => {
        selectorText = selectorText.replace(`:${variant}`, '');
      });
      if (el.classList.contains(selectorText)) {
        let variantApplied = 'responsive';
        tailwindConfig.stateVariants.forEach(variant => {
          if (selectorText.indexOf(`${variant}:`) !== -1) {
            variantApplied = variant;
          }
        });
        appliedClasses.push({
          styles: classes[i].style,
          variant: variantApplied
        });
      }
    }
    appliedClasses.forEach(classdata => {
      for (let i = classdata.styles.length; i--; ) {
        const nameString = classdata.styles[i];
        properties[classdata.variant][nameString] =
          classdata.styles[nameString];
      }
    });
    return properties;
  },
  getAppliedClassProperties(el) {
    let rules = [...el.ownerDocument.styleSheets];
    rules = rules.filter(
      ({ href }) =>
        !href || href.indexOf(window.gdEnv.VUE_APP_FRONTEND_URL) !== -1
    );
    const classes = {
      all: []
    };
    const mediaSelectors = {};
    for (const screen in tailwindConfig.screens) {
      classes[screen] = [];
      mediaSelectors[`(min-width: ${tailwindConfig.screens[screen]})`] = screen;
    }
    rules.forEach(sheet =>
      [...(sheet.cssRules || sheet.rules || [])].forEach(rule => {
        if (rule.selectorText) classes.all.push(rule);
        else if (mediaSelectors[rule.conditionText]) {
          classes[mediaSelectors[rule.conditionText]] = [
            ...classes[mediaSelectors[rule.conditionText]],
            ...rule.cssRules
          ];
        }
      })
    );
    const properties = {};
    for (const screen in classes) {
      properties[screen] = this.getPropertiesFromcssClasses(
        el,
        classes[screen]
      );
    }
    return properties;
  },
  mapVariantsToStylesheet(variants, selector) {
    const mapProperties = (properties, suffix) => {
      if (!Object.keys(properties).length) return '';
      let styles = `
      ${selector + suffix} {
      `;
      for (const property in properties) {
        styles += `${property}: ${properties[property]};
        `;
      }
      styles += '}';
      return styles;
    };
    const mapStatevariants = screen => {
      let styles = '';
      for (const variant in screen) {
        const suffix = variant === 'responsive' ? '' : `:${variant}`;
        styles += mapProperties(screen[variant], suffix);
      }
      return styles;
    };
    let sheet = '';
    for (const screenVariant in variants) {
      const section = mapStatevariants(variants[screenVariant]);
      if (!section) continue;
      if (screenVariant === 'all') {
        sheet += section;
      } else {
        sheet += `
      @media (min-width: ${tailwindConfig.screens[screenVariant]}) {
        ${section}
      }
      `;
      }
    }
    return sheet;
  },
  clearOrphanedLegacyStyles(contents) {
    const checkId = (id, stylesheet) => {
      if (!contents.querySelector(`.gp-component-id-${id}`)) {
        if (stylesheet.classList.contains('style-gp-body')) return;
        stylesheet.remove();
      }
    };
    contents.querySelectorAll('style').forEach(stylesheet => {
      stylesheet.classList.forEach(className => {
        if (className.substr(0, 6) === 'style-' && className.length < 21) {
          const id = className.replace('style-', '');
          checkId(id, stylesheet);
        }
      });
    });

    if (!contents.getElementById('blocks-container').innerHTML.trim()) {
      contents.getElementById('blocks-container').innerHTML = '';
    }
  }
};
