import contentUtils from './pageContentUtils';
import globalUtils from './pageGlobalUtils';
import miscUtils from './pageMiscUtils';
import popupUtils from './pagePopupUtils';
import setupUtils from './pageSetupUtils';

const pageUtils = {
  ...contentUtils,
  ...popupUtils,
  ...setupUtils,
  ...globalUtils,
  ...miscUtils
};

export default pageUtils;
