import shortid from 'shortid';
import builderSettings from './pageData';
// methods of Page class are divided into following mixins to reduce file size. consider searching in whole Page folder for any methods
import pageBlocksMixin from './pageBlocksMixin';
import pageHelperMixin from './pageHelperMixin';
import pageLoadingMixin from './pageLoadingMixin';
import pageOperationMixin from './pageOperationMixin';
import pageSavingMixin from './pageSavingMixin';
// TODO: check feasibility of using sublass instead of mixins (https://softwareengineering.stackexchange.com/questions/378815/whats-the-best-way-to-handle-a-large-multi-file-class-in-javascript)

class Page {
  constructor(data = {}) {
    if (data.builder_settings) {
      this.toInject = null;
      this.assetsLoading = 0;
      this.height = 0;
      this.modified = false;
      this.customFonts = data.customFonts;
      this.settings = data || {};
      this.mapBuilderSettings(data.builder_settings);
      this.initializeContentBuilderSettings(data.builder_settings);
      delete this.settings.builder_settings;
    } else {
      this.mapBuilderSettings(data);
      this.settings = data.settings;
    }
  }

  mapBuilderSettings(settings = {}) {
    builderSettings.forEach(setting => {
      this[setting] = settings[setting] === 'false' ? false : settings[setting];
    });

    if (!this.pageId) this.pageId = shortid.generate();

    if (settings.componentConfigs) {
      this.componentConfigs =
        typeof settings.componentConfigs === 'string'
          ? JSON.parse(settings.componentConfigs)
          : settings.componentConfigs;
    }
    if (settings.componentDatas) {
      this.componentDatas =
        typeof settings.componentDatas === 'string'
          ? JSON.parse(settings.componentDatas)
          : settings.componentDatas;
    }
  }

  getBuilderSettings() {
    const settings = builderSettings.reduce(
      (set, setting) => ({ ...set, [setting]: this[setting] }),
      {}
    );
    settings.componentConfigs = JSON.stringify(this.componentConfigs);
    settings.componentDatas = JSON.stringify(this.componentDatas);
    return settings;
  }
}

// include all page mixins/methods into Page class
Object.assign(
  Page.prototype,
  pageOperationMixin,
  pageBlocksMixin,
  pageLoadingMixin,
  pageSavingMixin,
  pageHelperMixin
);

export default Page;
