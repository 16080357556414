import { minify } from 'terser';

const code = `(function () {
  function proxifySite(obj) {
    try {
        const pages = { ...obj.pages } || {};
        if (new Proxy({}, {}) instanceof Object) {
        delete obj.pages;
        obj.isProxy = true;
        return new Proxy(obj, {
            pages,
            get(target, prop) {
            if (prop === 'pages' && prop in this.pages) {
                return this.pages[prop];
            }
            if (prop === 'pages') {
                return this.pages;
            }
            if (prop in target) {
                return target[prop];
            }
            return undefined;
            },

            set(target, prop, val) {
            target[prop] = val;
            return true;
            }
        });
        }
        return undefined;
    } catch (error) {}
    const pages = { ...obj.pages } || {};
    if (new Proxy({}, {}) instanceof Object) {
        delete obj.pages;
        obj.isProxy = true;
        return new Proxy(obj, {
        pages,
        get(target, prop) {
            if (prop === 'pages' && prop in this.pages) {
            return this.pages[prop];
            }
            if (prop === 'pages') {
            return this.pages;
            }
            if (prop in target) {
            return target[prop];
            }
            return undefined;
        },

        set(target, prop, val) {
            target[prop] = val;
            return true;
        }
        });
    }
    return undefined;
    }

    if (!window.site && !window.encodeSite) {
        window.encodeSite = function(obj, siteId) {
            obj.pages = JSON.parse(atob(obj.pages));
            window.site = proxifySite(obj);
            return window.site;
        };
    }
})();`;

export default minify(code);
