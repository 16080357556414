import Importer from '@/builder/canvas/Importer';
import Page from './Page';

export default class Popup extends Page {
  async getContent() {
    if (!this.content) {
      await this.loadContent();
    }
    let content = await super.getContent();
    content = content || '';
    if (!this.popupSettings.parsed) {
      content = await Importer.setInternalStyles(content);
    }
    return (typeof content === 'object' ? content.content : content) || '';
  }

  get popupSettings() {
    return this.popup || {};
  }

  set popupSettings(settings) {
    this.popup = settings;
  }

  get displayName() {
    return this.attributes.displayName;
  }

  set displayName(name) {
    this.attributes.name = name;
    this.attributes.displayName = name;
  }

  get availablePages() {
    return this.popup.pages[0] || {};
  }

  set availablePages(page) {
    this.popup.pages = [page];
  }

  shouldShow(pageId) {
    if (this.popup.showOptionSelected === 'hide') {
      return !this.popup?.pages?.includes(pageId);
    }
    return (
      this.popup?.pages?.includes('all') || this.popup?.pages?.includes(pageId)
    );
  }
}
