import pageUtils from '@pages/builder/canvas/pageUtils';

const addGlobalStyles = () => {
  if (
    !window.contentBuilderSettings ||
    !window.contentBuilderSettings.globalStyles
  )
    return;
  let content = '';
  for (const style in window.contentBuilderSettings.globalStyles) {
    const styles = JSON.parse(
      window.contentBuilderSettings.globalStyles[style].styles
    );
    // eslint-disable-next-line no-undef
    content += pageUtils.getStylesheetForGlobals(style, styles);
  }
  const sheet = document.createElement('style');
  sheet.innerHTML = content;
  document.head.appendChild(sheet);
};
addGlobalStyles();
