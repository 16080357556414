const builderSettings = [
  'pageId',
  'displayName',
  'custom_html_body_top',
  'custom_html_body_bottom',
  'favicon',
  'title',
  'tags',
  'description',
  'custom_css',
  'custom_html_head',
  'custom_html_body_top',
  'custom_html_body_bottom',
  'og_image',
  'og_title',
  'og_description',
  'stylesProcessed',
  'type',
  'funnel_id',
  'webinar_id',
  'fontFamily',
  'popup',
  'isImported',
  'importMode',
  'importUrl',
  'importLayout',
  'importComplete',
  'disableIndexing',
  'hide_on_sitemap',
  'dublincoreMetas',
  'exitPrompt',
  'shouldRedirect',
  'redirectType',
  'externalRedirect',
  'internalRedirect',
  'megamenu',
  'megamenuOf',
  'globalBlocks',
  'shouldHideMegamenu',
  'shouldHideMegamenuDesks',
  'shouldHideMegamenuLaps',
  'shouldHideMegamenuHtabs',
  'shouldHideMegamenuVtabs',
  'shouldHideMegamenuMobiles',
  'megamenuWidthType',
  'megamenuMaxWidth',
  'megamenuAlignment',
  'componentConfigs',
  'componentDatas',
  'embeds',
  'globalStyles',
  'codeEmbeds',
  'webhooks',
  'integrations',
  'templateTags',
  'pageLinks',
  'popupLinks',
  'draft_mode'
];

export default builderSettings;
