import shortid from 'shortid';
import Vue from 'vue';

export default {
  namespaced: true,
  state: {
    blocks: {}
  },
  getters: {
    blocks(state) {
      return Object.values(state.blocks);
    }
  },
  mutations: {
    addBlock(state, block) {
      const id = shortid.generate();
      Vue.set(state.blocks, id, { ...block, id });
    },
    removeBlock(state, { id }) {
      Vue.delete(state.blocks, id);
    },
    setBlocks(state, blocks) {
      state.blocks = blocks;
    }
  },
  actions: {}
};
