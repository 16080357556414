import uikitConfig from '@groovepages/gp-page-ui-kit/tailwind';
import colorConvert from 'color-convert';

const emptyScreenStateObject = () => {
  const states = {};
  const screens = ['all', ...Object.keys(uikitConfig.screens)];
  screens.forEach((screen) => {
    states[screen] = {};
  });
  return states;
};
const convertColor = (colorString, opacity) => {
  if (typeof opacity === 'undefined' || opacity === '') opacity = 1;
  const colorArray = colorString
    .split('(')[1]
    .split(')')[0]
    .split([','])
    .map((color) => parseInt(color.trim()));
  colorArray.pop();
  const rgb = colorConvert.hsl.rgb(colorArray);
  return `rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, ${opacity})`;
};
const styleFunctions = {
  flattenOptionsAndGetProperty(property, element) {
    const tempProperty = JSON.parse(JSON.stringify(property));
    let flattenedOptions = [];
    property.options.forEach((option) => {
      if (typeof option === 'string') flattenedOptions.push(option);
      else flattenedOptions = flattenedOptions.concat(option.values);
    });
    tempProperty.options = flattenedOptions;
    styleFunctions.getTailwindProperty(tempProperty, element);
    property.selected = tempProperty.selected;
    property.styleProperties = tempProperty.styleProperties;
    property.stateVariants = uikitConfig.modules[property.module];
    property.stateVariants = property.stateVariants || ['responsive'];
  },
  getTailwindProperty(property, element) {
    property.stateVariants = uikitConfig.modules[property.module];
    if (!Array.isArray(property.options)) {
      property.stateVariants =
        property.stateVariants || uikitConfig.modules[property.options];
      property.options = Object.keys(uikitConfig[property.options]);
    }
    property.stateVariants = property.stateVariants || ['responsive'];
    const classes = [];
    property.selected = {
      all: {},
      sm: {},
      md: {},
      lg: {},
      xl: {},
    };
    property.stateVariants.forEach((stateVariant) => {
      property.options.forEach((option) => {
        classes.push(
          styleFunctions.getTailwindClasses(
            property,
            option,
            stateVariant,
            element
          )
        );
      });
    });
    property.styleProperties = classes;
  },
  getTailwindClasses(property, option, stateVariant, element) {
    const statePrefix = stateVariant === 'responsive' ? '' : `${stateVariant}:`;
    let optionClass = statePrefix + property.classPrefix;
    if (option !== 'default') {
      optionClass = `${optionClass}-${option}`;
    }
    if (!property.classPrefix) optionClass = statePrefix + option;
    if (element.classList.contains(optionClass)) {
      property.selected.all[stateVariant] = option;
    }
    if (element.classList.contains(`sm:${optionClass}`)) {
      property.selected.sm[stateVariant] = option;
    }
    if (element.classList.contains(`md:${optionClass}`)) {
      property.selected.md[stateVariant] = option;
    }
    if (element.classList.contains(`lg:${optionClass}`)) {
      property.selected.lg[stateVariant] = option;
    }
    if (element.classList.contains(`xl:${optionClass}`)) {
      property.selected.xl[stateVariant] = option;
    }
    return { option, class: optionClass };
  },
  getSidedProperty(property, element) {
    property.options = Object.keys(uikitConfig[property.options]);
    // TODO: move option rearrange to here
    property.stateVariants = uikitConfig.modules[property.module];
    property.stateVariants = property.stateVariants || ['responsive'];
    property.styleProperties = [];
    property.selected = {
      all: {},
      sm: {},
      md: {},
      lg: {},
      xl: {},
    };
    property.stateVariants.forEach((stateVariant) => {
      styleFunctions.getSidedPropertyVariant(property, element, stateVariant);
    });
  },
  getSidedPropertyVariant(property, element, stateVariant) {
    property.selected.all[stateVariant] = {};
    property.selected.sm[stateVariant] = {};
    property.selected.md[stateVariant] = {};
    property.selected.lg[stateVariant] = {};
    property.selected.xl[stateVariant] = {};
    const stateSuffix = stateVariant === 'responsive' ? '' : `:${stateVariant}`;
    const checkSides = (prefix, option, selected) => {
      let optionClassAll = `${
        prefix + stateSuffix + property.classPrefix
      }-${option}`;
      if (property.propertyName === 'margin') {
        if (option.charAt(0) === '-') {
          optionClassAll = `${
            `${prefix  }-${  stateSuffix  }${property.classPrefix}`
          }${option}`;
        }
      }
      property.styleProperties.push({ class: optionClassAll });
      if (element.classList.contains(optionClassAll)) {
        selected.all = option;
      }
      for (const side in property.sides) {
        let optionClass = `${
          prefix + stateSuffix + property.classPrefix + side
        }-${option}`;
        if (property.propertyName === 'margin') {
          if (option.charAt(0) === '-') {
            optionClass = `${
              `${prefix  }-${  stateSuffix  }${property.classPrefix  }${side}`
            }${option}`;
          }
        }
        property.styleProperties.push({ class: optionClass });
        if (element.classList.contains(optionClass)) {
          selected[side] = option;
        }
      }
    };
    property.options.forEach((option) => {
      checkSides('', option, property.selected.all[stateVariant]);
      checkSides('sm:', option, property.selected.sm[stateVariant]);
      checkSides('md:', option, property.selected.md[stateVariant]);
      checkSides('lg:', option, property.selected.lg[stateVariant]);
      checkSides('xl:', option, property.selected.xl[stateVariant]);
    });
  },
  setSidedProperty(property, element) {
    property.styleProperties.forEach((styleClass) => {
      element.classList.remove(styleClass.class);
    });
    property.stateVariants.forEach((stateVariant) => {
      styleFunctions.setSidedPropertyVariant(property, element, stateVariant);
    });
  },
  setSidedPropertyVariant(property, element, stateVariant) {
    const stateSuffix = stateVariant === 'responsive' ? '' : `:${stateVariant}`;
    const sides = { all: true, ...property.sides };
    for (const side in sides) {
      const sideName = side === 'all' ? '' : side;
      if (property.selected.all[stateVariant][side]) {
        if (
          property.propertyName === 'margin' &&
          property.selected.all[stateVariant][side].charAt(0) === '-'
        ) {
          const classtoAdd = `-${
            property.classPrefix + stateSuffix + sideName
          }${property.selected.all[stateVariant][side]}`;
          element.classList.add(classtoAdd);
        } else {
          element.classList.add(
            `${property.classPrefix + stateSuffix + sideName}-${
              property.selected.all[stateVariant][side]
            }`
          );
        }
      }
      if (property.selected.sm[stateVariant][side]) {
        if (
          property.selected.sm[stateVariant][side].charAt(0) === '-' &&
          property.propertyName === 'margin'
        ) {
          const classtoAdd = `sm:-${
            property.classPrefix + stateSuffix + sideName
          }${property.selected.sm[stateVariant][side]}`;
          element.classList.add(classtoAdd);
        } else {
          element.classList.add(
            `sm:${property.classPrefix + stateSuffix + sideName}-${
              property.selected.sm[stateVariant][side]
            }`
          );
        }
      }
      if (property.selected.md[stateVariant][side]) {
        if (
          property.selected.md[stateVariant][side].charAt(0) === '-' &&
          property.propertyName === 'margin'
        ) {
          const classtoAdd = `md:-${
            property.classPrefix + stateSuffix + sideName
          }${property.selected.md[stateVariant][side]}`;
          element.classList.add(classtoAdd);
        } else {
          element.classList.add(
            `md:${property.classPrefix + stateSuffix + sideName}-${
              property.selected.md[stateVariant][side]
            }`
          );
        }
      }
      if (property.selected.lg[stateVariant][side]) {
        if (
          property.selected.lg[stateVariant][side].charAt(0) === '-' &&
          property.propertyName === 'margin'
        ) {
          const classtoAdd = `lg:-${
            property.classPrefix + stateSuffix + sideName
          }${property.selected.lg[stateVariant][side]}`;
          element.classList.add(classtoAdd);
        } else {
          element.classList.add(
            `lg:${property.classPrefix + stateSuffix + sideName}-${
              property.selected.lg[stateVariant][side]
            }`
          );
        }
      }
      if (property.selected.xl[stateVariant][side]) {
        if (
          property.selected.xl[stateVariant][side].charAt(0) === '-' &&
          property.propertyName === 'margin'
        ) {
          const classtoAdd = `xl:-${
            property.classPrefix + stateSuffix + sideName
          }${property.selected.xl[stateVariant][side]}`;
          element.classList.add(classtoAdd);
        } else {
          element.classList.add(
            `xl:${property.classPrefix + stateSuffix + sideName}-${
              property.selected.xl[stateVariant][side]
            }`
          );
        }
      }
    }
  },
  getBackgroundImage(property, element, saved) {
    property.selected = emptyScreenStateObject();
    // convert from old format
    if (saved && typeof saved === 'string') {
      property.selected.all.responsive = saved;
    } else if (saved) {
      Object.keys(saved).forEach((screen) => {
        property.selected[screen] = saved[screen];
      });
    }
  },

  setBackgroundImage(property) {
    const styleMap = emptyScreenStateObject();
    if (typeof property.selected === 'string') {
      property.selected = { all: { responsive: property.selected } };
    }
    Object.keys(property.selected).forEach((screen) => {
      if (
        property.selected[screen].responsive &&
        property.selected[screen].responsive.indexOf('linear-gradient(') ===
          -1 &&
        property.selected[screen].responsive !== 'none'
      ) {
        styleMap[screen].responsive = {
          'background-image': `url("${property.selected[screen].responsive}")`,
        };
      } else if (property.selected[screen].responsive === 'none') {
        styleMap[screen].responsive = {
          'background-image': 'none',
        };
      } else if (property.selected[screen].responsive) {
        styleMap[screen].responsive = {
          'background-image': property.selected[screen].responsive,
        };
      }
    });
    return styleMap;
  },
  getParallax(property, element, saved) {
    property.selected = saved || emptyScreenStateObject();
  },
  setParallax(property) {
    const styleMap = emptyScreenStateObject();
    Object.keys(property.selected).forEach((screen) => {
      if (property.selected[screen].responsive)
        styleMap[screen].responsive = {
          'background-attachment': 'fixed',
        };
    });
    return styleMap;
  },

  getIDProperty(property, element) {
    property.selected = emptyScreenStateObject();
    property.selected.all.responsive = element.id;
  },

  setIDProperty(property, element) {
    const previous = element.id;
    element.classList.remove(`data-gp-attributevalue-id-${previous}`);
    element.classList.add(
      'data-gp-attribute-id',
      `data-gp-attributevalue-id-${property.selected.all.responsive}`
    );
    element.id = property.selected.all.responsive;
  },

  getCustomClasses(property, element, saved) {
    element.removeAttribute('data-custom-classes');
    property.selected = emptyScreenStateObject();
    if (saved && typeof saved === 'string') {
      property.selected.all.responsive = saved;
    } else if (saved) property.selected.all.responsive = saved.all.responsive;
    if (!property.selected.all.responsive) {
      property.selected.all.responsive = '';
    }
  },

  setCustomClasses(property, element, saved) {
    let oldClasses =
      element.getAttribute('data-custom-classes') ||
      (saved && saved.all && saved.all.responsive) ||
      '';
    oldClasses = oldClasses.split(' ');
    oldClasses.forEach((oldClass) => {
      if (oldClass) element.classList.remove(oldClass);
    });
    element.setAttribute(
      'data-custom-classes',
      property.selected.all.responsive
    );
    const newClasses = property.selected.all.responsive.split(' ');
    newClasses.forEach((newClass) => {
      if (newClass) element.classList.add(newClass);
    });
  },

  getCustomCss(property, element, saved) {
    property.selected = emptyScreenStateObject();
    if (saved && typeof saved === 'string') {
      property.selected.all.responsive = saved;
    } else if (saved) property.selected.all.responsive = saved.all.responsive;
    if (!property.selected.all.responsive) {
      property.selected.all.responsive = '';
    }
  },

  setCustomCss(property, element) {
    let id = element.getAttribute('data-gp-component-id');
    if (!id) id = element.parentNode.getAttribute('data-gp-component-id');
    const styleClass = `style-${id}-custom-css`;
    const previous = element.ownerDocument.querySelectorAll(`.${styleClass}`);
    for (let i = 0; i < previous.length; i++) {
      previous[i].remove();
    }
    if (!property.selected) return;
    let {selected} = property;
    if (typeof property.selected === 'object') {
      selected = property.selected.all.responsive;
    }
    const styleSheet = document.createElement('style');
    styleSheet.innerHTML = `[data-gp-component-id="${id}"] {
      ${selected}
    }`;
    styleSheet.classList.add(`style-${id}`);
    styleSheet.classList.add(styleClass);
    element.parentNode.insertBefore(styleSheet, element);
  },
  getColorOrOverlayColor(property, element, saved) {
    let toCheck = element;
    if (element.querySelectorAll('[data-section-overlay]').length) {
      toCheck = element.querySelectorAll('[data-section-overlay]')[0];
    }
    return styleFunctions.getColorOpacity(property, toCheck, saved);
  },
  setColorOrOverlayColor(property) {
    return styleFunctions.setColorOpacity(property);
  },
  getColorOpacity(property, element, saved) {
    styleFunctions.getTailwindProperty(property, element);
    if (!saved) saved = {};
    const screens = ['all', ...Object.keys(uikitConfig.screens)];
    const setDefaults = (parameter) => {
      if (!saved[parameter]) saved[parameter] = {};
      if (!property.selected[parameter]) property.selected[parameter] = {};
      property.stateVariants.forEach((stateVariant) => {
        screens.forEach((screen) => {
          if (!saved[parameter][screen]) saved[parameter][screen] = {};
          if (!property.selected[parameter][screen]) {
            property.selected[parameter][screen] = {};
          }
          property.selected[parameter][screen][stateVariant] =
            saved[parameter][screen][stateVariant] || '';
          if (saved[screen] && saved[screen][stateVariant]) {
            property.selected[screen][stateVariant] =
              saved[screen][stateVariant];
          }
        });
      });
    };
    setDefaults('opacities');
    setDefaults('hex');
  },
  setColorOpacity(property) {
    const styleMap = {};
    const screens = ['all', ...Object.keys(uikitConfig.screens)];
    screens.forEach((screen) => {
      styleMap[screen] = styleFunctions.setColorOpacityVariant(
        property,
        screen
      );
    });
    return styleMap;
  },
  setColorOpacityVariant(property, screen) {
    const styles = {};
    property.stateVariants.forEach((variant) => {
      if (property.selected[screen][variant]) {
        styles[variant] = {};
        const color = property.selected[screen][variant];
        const opacity =
          property.selected.opacities &&
          property.selected.opacities[screen][variant];
        const colorVar = uikitConfig.colors[color];
        if (!this.isHexColor(color)) {
          styles[variant][property.opacityProperty] = convertColor(
            colorVar,
            opacity
          );
        } else {
          styles[variant][property.opacityProperty] = this.convertHex(
            color,
            opacity
          );
        }
      }
    });
    return styles;
  },
  isHexColor(hex) {
    if (hex !== undefined && hex !== null) {
      if (hex.startsWith('#')) hex = hex.substring(1);
    }
    return (
      typeof hex === 'string' && hex.length === 6 && !isNaN(Number(`0x${hex}`))
    );
  },
  convertHex(hex, opacity) {
    if (typeof opacity === 'undefined' || opacity === '') opacity = 1;
    hex = hex.replace('#', '');
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);
    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
  },
  reverseTailwindOptions(property, element) {
    styleFunctions.getTailwindProperty(property, element);
    property.options = property.options.reverse();
  },
};
export default styleFunctions;
