// DOM manipulation library, based on Chirashi and to be extended with other libraries and functions
import Slick from 'slick';
import { closest } from 'chirashi';
import Velocity from 'velocity-animate';

const Dom = {
  closest,
  matches: Slick.matches,
  contains: Slick.contains,
  fadeIn(element, options) {
    Velocity(element, 'fadeIn', options);
  },
  fadeOut(element, options) {
    Velocity(element, 'fadeOut', options);
  },
  slideUp(element, options) {
    Velocity(element, 'slideUp', options);
  },
  slideDown(element, options) {
    Velocity(element, 'slideDown', options);
  }
};

export default Dom;
