import Page from '@/builder/canvas/Page';
import store from '@/builder/data/store';
import api from '@groovepages/gd-lib/api';
import { customBase64Decode } from '@pages/shared/utils';

function singleBreakpointProperty(property) {
  const normalisedProperty = property.toLowerCase();
  if (
    ['margin', 'padding'].includes(normalisedProperty) &&
    !this.$store.state.builder.cascadeSpace
  ) {
    return true;
  }

  if (
    normalisedProperty.includes('size') &&
    !['fontsize', 'backgroundsize'].includes(normalisedProperty)
  ) {
    return true;
  }

  return false;
}

export const getBreakpointText = label => {
  switch (label) {
    case 'xs':
      return '(max-width: 639px)';
    case 'sm':
      return '(max-width: 767px) and (min-width: 640px)';
    case 'md':
      return '(max-width: 991px) and (min-width: 768px)';
    case 'lg':
      return '(max-width: 1199px) and (min-width: 992px)';
    case 'xl':
      return '(min-width: 1200px)';
    default:
      return 'all';
  }
};

export default {
  async getBlockContent(src) {
    this.content = document.createElement('DIV');
    const url = `${window.gdEnv.VUE_APP_API_URL}/groovepages/elements/data/${src.id}`;
    const res = await api.get(url);
    return customBase64Decode(res.markup);
  },
  async getBlock({ id, type }) {
    let markup;
    let block;

    const blocksMegamenusWidth = {
      12264737: 390,
      12264738: 810,
      12264739: 'full',
      12264740: 'full',
      12295700: 'full',
      12264736: 'full',
      12264734: 'full',
      12264735: 'full',
      12264730: 280,
      12264731: 580,
      12264732: 860,
      12264733: 'full'
    };

    this.content = document.createElement('DIV');
    if (type === 'global_block') {
      let page = store.getters.pagesById[id];
      if (!page) {
        try {
          const url = `${window.gdEnv.VUE_APP_API_URL}/groovepages/pages/data/${id}`;
          const data = await api.get(url);
          page = new Page(data);
        } catch (error) {
          console.error(error);
          return {};
        }
      }

      if (!page.content) {
        await page.loadContent();
      }
      markup = page.content;
      block = {
        type,
        id: page.settings.id,
        revision: page.settings.revision
      };
    } else {
      const url = `${window.gdEnv.VUE_APP_API_URL}/groovepages/elements/data/${id}`;
      block = await api.get(url);
      markup = customBase64Decode(block.markup);
    }

    return {
      ...block,
      markup,
      blockswidth: blocksMegamenusWidth[id]
    };
  },
  getComponentStylesheets(id, canvas) {
    const excludedStylesheet = (sheet, componentId) => {
      if (sheet.classList.contains(`style-${componentId}-custom-css`))
        return true;
      if (sheet.id === 'custom-css') return true;
      if (sheet.href && !sheet.href.includes(window.gdEnv.VUE_APP_FRONTEND_URL))
        return true;
      // REVIEW: possible breaking change during cleaning
      if (
        sheet.hasAttribute('data-gp-styled-element') &&
        componentId !== sheet.getAttribute('data-gp-styled-element')
      ) {
        return true;
      }
      //
      return false;
    };
    let stylesheets = [...canvas.styleSheets];
    stylesheets = stylesheets.filter(
      sheet => !excludedStylesheet(sheet.ownerNode, id)
    );
    return stylesheets;
  },
  callStyleWriter(property, value, addStylesheet) {
    let breakpoint = this.$store.getters.screenBreakpoint;
    let cascade = false;
    if (
      !singleBreakpointProperty.call(this, property) &&
      !this.$store.state.builder.singleScreenOverride
    ) {
      breakpoint = getBreakpointText('xl');
      cascade = true;
    }
    const stylingMethod = addStylesheet ? 'setStyle' : 'softSetStyle';
    this.$canvas[stylingMethod](property, value, {
      breakpoint,
      state: this.state,
      cascade
    });
  }
};
