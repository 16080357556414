import Vue from 'vue';

const getInitialState = () => ({
  isSidebarOpen: false,
  activeMainMenu: null,
  menus: {
    element: {
      levels: [
        {
          id: 0,
          component: 'component-types',
          props: {}
        }
      ]
    },
    assets: {
      levels: [
        {
          id: 0,
          component: 'assets-types',
          props: {}
        }
      ]
    },
    theme: { levels: [] },
    settings: {
      levels: [
        {
          id: 0,
          component: 'settings-types',
          props: {}
        }
      ]
    },
    misc: {
      levels: [
        {
          id: 0,
          component: 'misc-types',
          props: {}
        }
      ]
    }
  }
});

const initialState = getInitialState();

export default {
  namespaced: true,
  state: initialState,
  getters: {
    activeMenuLevels(state) {
      return state.menus[state.activeMainMenu]?.levels || [];
    }
  },
  mutations: {
    openSidebar(state, menuName = null) {
      state.isSidebarOpen = true;
      if (menuName) state.activeMainMenu = menuName;
      // clean levels of other menus
      Object.keys(state.menus || {}).forEach(menu => {
        if (menu === menuName) return;
        state.menus[menu].levels = state.menus[menu].levels.splice(0, 1);
      });
    },
    setSidebarLevel(
      state,
      {
        menu,
        level,
        component,
        key,
        props = {},
        cleanLevel,
        totalLevelsToClean = 1
      }
    ) {
      if (level > state.menus[menu].levels.length) return;
      if (cleanLevel)
        state.menus[menu].levels.splice(cleanLevel, totalLevelsToClean);
      Vue.set(state.menus[menu].levels, level, {
        id: level,
        component,
        props,
        key
      });
    },
    closeSidebar(state, resetState = false) {
      if (resetState) {
        Object.assign(state, getInitialState());
      } else {
        state.isSidebarOpen = false;
      }
    }
  }
};
