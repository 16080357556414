// splitted methods from 'pageUtils' at 'gp-ui/src/builder/canvas/pageUtils/index.js'

import store from '@/builder/data/store';

export default {
  getPageHead(settings, styles = []) {
    let styleString = '';
    styles.forEach(style => {
      styleString += `<link href="${style}" rel="stylesheet">`;
    });

    return `
    ${this.getPageMetas(settings)}
    ${styleString}
    ${settings.custom_html_head}
    
     <script src="https://kit.fontawesome.com/e7647a48d4.js" crossorigin="anonymous"></script>
     
     <style id="page-css">
      ${settings.page_css}
     </style>
     <style id="custom-css">
      ${settings.custom_css}
     </style>
     <script type="text/javascript">
        window.websiteurl = "${this.previewMainLink()}";
        var megamenuEvent = new Event('megamenuLoaded');

        function setPageUrl(button,page,queryString) {
          var pageUrl = site.pages[page]?.url;
          button.setAttribute('href', pageUrl + (queryString || ''));
        }

        ${this.getQueryParamScript()}

        ${this.getQuickPopupScript()}
     </script>
     <script>${settings.proxyCode}</script>
     `;
  },
  getPageBody(settings, content, scripts = []) {
    let scriptString = '';
    scripts.forEach(script => {
      scriptString += `<script src="${script}"></script>`;
    });

    let pageBodyMarkup = '';

    pageBodyMarkup += this.getIEwarningScript();
    if (settings.exitPrompt == 'true') {
      pageBodyMarkup += `<script id="Exist-Prompt">
      (function() {
         if(!window.inBuilder) {
            window.addEventListener('beforeunload', (event) => {
              event.preventDefault();
              return event.returnValue = 'Are you sure you want to leave?';
            });
         }
      })();
      </script>`;
    }
    pageBodyMarkup += `<div id="custom-top">
      ${settings.custom_html_body_top}
    </div>`;
    pageBodyMarkup += this.getSiteSettingsPopulator(
      settings.siteId,
      this.encodePageSiteSettings(settings.onPageSiteSettings)
    );
    pageBodyMarkup += `<div id="page-container" data-gp-page-id="${settings.pageId}">
      <div class="blocks-container" id="blocks-container">
        ${content}
      </div>
      <div id="blocks-staging" style="top: 0px; left: 0; position: absolute;"></div>
    </div>
    <div id="custom-bottom">
      ${settings.custom_html_body_bottom}
    </div>
    <script>
      (function() {
        var callSiteCallbacks = function() {
          if(window.site && window.site.subscribed) {
            site.subscribed.forEach(callback => {
              callback();
            });
          }
        };
        document.readyState !== 'loading' ? callSiteCallbacks() : document.addEventListener("DOMContentLoaded", callSiteCallbacks);
      })();
    </script>
    ${scriptString}`;
    return pageBodyMarkup;
  },
  getPageCustomHTMLs(pageSettings, siteSettings, inBuilder) {
    let htmlHead =
      (pageSettings.custom_html_head || '') +
      (siteSettings.custom_html_head || '');
    let htmlBodyTop =
      (pageSettings.custom_html_body_top || '') +
      (siteSettings.custom_html_body_top || '');
    let htmlBodyBottom =
      (pageSettings.custom_html_body_bottom || '') +
      (siteSettings.custom_html_body_bottom || '');

    if (inBuilder) {
      htmlHead = '';
      htmlBodyTop = '';
      htmlBodyBottom = '';
    }

    return { htmlHead, htmlBodyTop, htmlBodyBottom };
  },
  getGroupFavicon(pageSettings, siteSettings) {
    let groupFavicon = null;
    if (pageSettings.funnel_id) {
      const funnelGroup = siteSettings.funnels.filter(
        e => e.id === pageSettings.funnel_id
      )[0];
      if (funnelGroup?.favicon) groupFavicon = funnelGroup.favicon;
    }
    if (pageSettings.webinar_id) {
      const webinarGroup = siteSettings.webinars.filter(
        e => e.id === pageSettings.webinar_id
      )[0];
      if (webinarGroup?.favicon) groupFavicon = webinarGroup.favicon;
    }
    return groupFavicon;
  },
  // eslint-disable-next-line sonarjs/cognitive-complexity, complexity
  getPageContentSettings(pageSettings, siteSettings, inBuilder) {
    const { htmlHead, htmlBodyTop, htmlBodyBottom } = this.getPageCustomHTMLs(
      pageSettings,
      siteSettings,
      inBuilder
    );

    const pageFont = pageSettings.fontFamily ?? siteSettings.fontFamily;

    const groupFavicon = this.getGroupFavicon(pageSettings, siteSettings);

    return {
      dublincoreMetas: pageSettings.dublincoreMetas || '',
      disableIndexing: pageSettings.disableIndexing || false,
      shouldRedirect: pageSettings.shouldRedirect || false,
      redirectType: pageSettings.redirectType || '',
      externalRedirect: pageSettings.externalRedirect || '',
      internalRedirect: pageSettings.internalRedirect || '',
      exitPrompt: pageSettings.exitPrompt || false,
      tags: pageSettings.tags || siteSettings.tags || '',
      description: pageSettings.description || siteSettings.description || '',
      title: pageSettings.title || pageSettings.displayName || '',
      favicon:
        pageSettings.favicon || groupFavicon || siteSettings.favicon || '',
      custom_html_head: htmlHead,
      page_css: pageFont ? `body { font-family: ${pageFont} }` : '',
      custom_css: `${siteSettings.custom_css ||
        ''} *{} ${pageSettings.custom_css || ''}`,
      og_title: pageSettings.og_title || siteSettings.og_title || '',
      og_description:
        pageSettings.og_description || siteSettings.og_description || '',
      og_image: pageSettings.og_image || siteSettings.og_image || '',
      custom_html_body_top: htmlBodyTop,
      pageId: pageSettings.pageId || '',
      siteId: siteSettings.siteId || '',
      custom_html_body_bottom: htmlBodyBottom,
      onPageSiteSettings: siteSettings.onPageSiteSettings || {},
      popups: siteSettings.popups || '',
      globalStyles: siteSettings.globalStyles || '',
      proxyCode: siteSettings.proxyCode || '',
      showCookieAlert: inBuilder ? null : siteSettings.showCookieAlert,
      cookieAlertType: inBuilder ? null : siteSettings.cookieAlertType,
      cookieTitle: siteSettings.cookieTitle,
      cookieDescription: siteSettings.cookieDescription,
      cookieCTAlabel: siteSettings.cookieCTAlabel
    };
  },
  getFullPage(content, settings, assets, excludePoweredBy) {
    if (!settings) settings = this.getPageContentSettings({}, {});
    let pageBody = this.getPageBody(settings, content, assets.scripts);
    if (settings.preview) {
      pageBody += this.getPreviewMarkup();
    }
    if (settings.showCookieAlert) {
      pageBody += `
        <div class="gdpr-bar gp-cookie-alert ${
          settings.cookieAlertType
        }" data-html2canvas-ignore>
          <div class="container">
            ${this.getCookieContent(settings)}
          </div>
        </div>
      `;
    }
    const analytics = this.getAnalyticsCode(settings);

    let poweredByNotice = '';

    if (store.state.pages.showGrooveBranding) {
      poweredByNotice = this.getBrandingContent(settings);
    }

    return `<!DOCTYPE html>
    <html lang="en">
      <head>
        ${this.getPageHead(settings, assets.styles)}
        ${analytics}
      </head>
      <body data-gp-site-id="${settings.siteId}" data-gp-style class="gpblsh">
        <!-- Start Popups -->
        <script> if(!window.mergeContentSettings) { function mergeContentSettings(){}}</script>
        ${settings.popups}
        <!-- End Popups -->
        ${pageBody}
        ${excludePoweredBy ? '' : poweredByNotice}
      </body>
    </html>
    `;
  },
  getDefaultPageAssets(type, screenshot) {
    let jsPrefix = '';
    if (process.env.NODE_ENV !== 'development' || screenshot) {
      jsPrefix = '/js';
    }
    let file = 'inpage_builder';
    if (type === 'full_content' || type === 'preview') {
      file = 'inpage_published';
    } else if (type === 'parsed' && screenshot) {
      file = 'inpage_published_parsed';
    } else if (type === 'parsed') {
      file = 'inpage_published';
    }
    const assets = {
      scripts: [`${window.gdEnv.VUE_APP_FRONTEND_URL + jsPrefix}/${file}.js`],
      styles: [`${window.gdEnv.VUE_APP_FRONTEND_URL}/css/${file}.css`]
    };
    if (type === 'preview') {
      assets.scripts.push(
        `${window.gdEnv.VUE_APP_FRONTEND_URL + jsPrefix}/preview.js`
      );
    }

    return assets;
  },
  previewMainLink() {
    const {
      selectedDomain,
      publish_folder: publishFolder,
      subDomain
    } = store.state.site;
    if (selectedDomain)
      return `https://${subDomain ? `${subDomain}.` : ''}${selectedDomain}/${
        selectedDomain.includes(`.${window.gdEnv['VUE_APP_GROOVEPAGES_URL'].match(/\/\/(.+)$/)[1] || 'groovepages.com'}`) && publishFolder
          ? `${publishFolder}/`
          : ''
      }`.toLowerCase();
    return null;
  },
  getCookieContent(settings) {
    const cookieTitle = `<h2 class="gp-cookie-heading">${settings.cookieTitle ||
      'This website uses cookies'}</h2>`;
    const cookieDescription = `<div class="gp-cookie-detail">${settings.cookieDescription ||
      'This website uses cookies in order to offer you the most relevant information. Please accept cookies for optimal performance.'}</div>`;
    const cookieCTA = `<button class="gdpr-close">${settings.cookieCTAlabel ||
      'Accept'}</button>`;

    return (
      (settings.cookieAlertType === 'cookie-3' ? '' : cookieTitle) +
      cookieDescription +
      cookieCTA
    );
  },
  encodePageSiteSettings(settings) {
    return {
      ...settings,
      pages: btoa(unescape(encodeURIComponent(JSON.stringify(settings.pages)))),
      baseURL: window.gdEnv.VUE_APP_API_URL
    };
  }
};
