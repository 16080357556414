
export default {
  namespaced: true,
  state: {
    activeMegamenu: null,
  },
  getters: {
    isMegamenuEditing(state) {
      return !!state.activeMegamenu;
    },
    megamenuPages(state, getters, rootState, { pages }) {
      return pages.filter(({type}) => type === 'megamenu').map(({ pageId }) => pageId);
    },
  },
  mutations: {
    setActiveMegamenu(state, payload) {
      state.activeMegamenu = payload;
    },
  },
  actions: {},
};
