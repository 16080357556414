export default class Model {
  attributes = {};

  defaultAttributes = {};

  hidden = [];

  appends = [];

  constructor(attributes) {
    this.fill(attributes);
  }

  fill(attributes) {
    this.attributes = { ...this.defaultAttributes, ...attributes };
    Object.keys(this.attributes).forEach(key => {
      if (!this.__lookupGetter__(key) || this.__lookupSetter__(key)) {
        this[key] = this.attributes[key];
      }
    });
  }

  getAttributes() {
    return Object.keys(this.attributes).reduce(
      (attributes, key) => ({
        ...attributes,
        [key]: typeof this[key] === 'undefined' ? attributes[key] : this[key]
      }),
      this.attributes
    );
  }

  toJSON() {
    const keys = Object.keys(this.attributes)
      .concat(this.appends)
      .filter(key => !this.hidden || this.hidden.includes(key));

    return keys.reduce((obj, key) => ({ ...obj, [key]: this[key] }), {});
  }
}
