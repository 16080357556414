export default {
    namespaced: true,
    state: {
      customSelected: {
        'box': '',
        'text': ''
      },
      negativeSelected: {
        'box': false,
        'text': false
      }
    },
    mutations: {
      setCustomSelected(state, { type, selected }) {
        state.customSelected[type] = selected;
      },
      setNegativeSelected(state, { type, selected }) {
        state.negativeSelected[type] = selected;
      }
    }
  };
  