// splitted methods from 'pageUtils' at 'gp-ui/src/builder/canvas/pageUtils/index.js'

import canvasUtils from '@groovepages/gd-canvas/utils';
import html2canvas from 'html2canvas';
import Promise from 'lodash-es/_Promise';

export default {
  refreshSiteData(contents, settings) {
    contents.getElementById('custom-css').innerHTML = settings.custom_css;
    contents.getElementById('page-css').innerHTML = settings.page_css;
  },
  extractContent(content, page) {
    const blockId = content.getAttribute('data-gp-block-id');

    [
      content,
      ...content.querySelectorAll('[class*="gp-component-id-"]')
    ].forEach(component => {
      const id = canvasUtils.getComponentIdFromClass(component);
      page
        .querySelectorAll(`style[data-gp-styled-element="${id}"]`)
        .forEach(stylesheet => {
          content.prepend(stylesheet.cloneNode(true));
        });
    });

    return {
      blockId,
      content,
      screenshotContent: this.getFullPage(
        content.outerHTML,
        null,
        this.getDefaultPageAssets('parsed', true),
        true
      )
    };
  },
  async getScreenshotImg(content, selector) {
    const screenshotFrame = document.createElement('iframe');
    screenshotFrame.style.height = '600px';
    screenshotFrame.style.width = '1200px';
    screenshotFrame.style.position = 'absolute';
    screenshotFrame.style.zIndex = '-10';
    screenshotFrame.style.top = '0';
    screenshotFrame.style.visibility = 'hidden';
    document.body.classList.add('overflow-hidden');
    document.body.appendChild(screenshotFrame);
    try {
      screenshotFrame.contentDocument.querySelector(
        'html'
      ).innerHTML = content.replace(
        /<!DOCTYPE html>|<html lang="en">|<\/html>/g,
        ''
      );
      screenshotFrame.contentDocument.body.style.margin = 0;
      screenshotFrame.contentDocument
        .querySelectorAll('[data-gp-block]')
        .forEach((block, idx) => idx > 4 && block.remove()); // take first 5 blcoks to avoid bottleneck in case of full page screenshot
      const element = selector
        ? screenshotFrame.contentDocument.querySelector(selector)
        : screenshotFrame.contentDocument.body;

      const options = {
        allowTaint: true,
        useCORS: true,
        scale: 0.25,
        x: element.getBoundingClientRect().x,
        y: element.getBoundingClientRect().y,
        height: selector ? element.clientHeight : 1200
      };

      const canvas = await html2canvas(element, options);

      return new Promise(resolve => {
        canvas.toBlob(b => resolve(b), 'image/webp', 0.9);
      });
    } catch (error) {
      console.log({ error });
      console.error(error);
    } finally {
      screenshotFrame.remove();
      document.body.classList.remove('overflow-hidden');
    }
  },
  checkNameUniqueAndValid(pages, name, links = []) {
    let valid = true;
    let error = '';
    const pattern = new RegExp(/^(?:[A-Za-z0-9_-]*)$/);
    if (!name || !pattern.test(name)) {
      valid = false;
      error = 'Invalid name';
    }
    if (pages.indexOf(name) !== -1) {
      valid = false;
      error = 'URL must be unique';
    }

    if (links.indexOf(name) !== -1) {
      valid = false;
      error = "Sorry, you can't rename the page because this name is used in the blog";
    }

    return { valid, error };
  },
  cleanContent(content) {
    content.innerHTML = content.innerHTML.replaceAll("['load','megamenuLoaded']", "['load','settingsLoaded','megamenuLoaded']");
    const classesToRemove = [
      'draggable--is-dragging',
      'draggable-container--is-dragging',
      'draggable-container--placed',
      'draggable--over',
      'draggable-container--over',
      'draggable-mirror',
      'draggable-source--placed',
      'ql-hidden',
      'gp-float-spacer'
    ];
    classesToRemove.forEach(item => {
      Array.from(content.getElementsByClassName(item)).forEach(element => {
        element.classList.remove(item);
      });
    });
    const itemsToRemove = [
      'drop-placeholder',
      'gp-drag-spacer',
      'gp-blocks-adder',
      'gp-blocks-adder--inner',
      'ql-tooltip',
      'ql-formats'
    ];
    itemsToRemove.forEach(item => {
      Array.from(content.getElementsByClassName(item)).forEach(element => {
        element.parentNode.removeChild(element);
      });
    });

    // remove empty styles and compress other styles
    [...content.getElementsByTagName('style')].forEach(s => {
      const { innerHTML } = s;
      if (!innerHTML.length) {
        s.remove();
      } else {
        let html = s.innerHTML;
        html = html.replace(/\n/g, ' ').replace(/\s{2,}/g, ' ');
        s.innerHTML = html;
      }
    });
  }
};
