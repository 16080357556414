import Importer from '@/builder/canvas/Importer';
import pageUtils from '@/builder/canvas/pageUtils';
import embeds from '@/builder/data/embeds';
import store from '@/builder/data/store';
import dragDropUtils from '@groovepages/gd-canvas/dragdrop/utils';
import textUtils from '@groovepages/gd-canvas/elements/textUtils';
import utils from '@groovepages/gd-canvas/utils';
import legacyStyleUtils from '@pages/builder/legacy/legacyStyleUtils';
import { customBase64Decode } from '@pages/shared/utils';
import shortid from 'shortid';

export default {
  async clone(pageNames, suffix = ' copy') {
    while (
      pageNames.includes(
        this.settings.name + suffix.toLowerCase().replace(/\s+/g, '-')
      )
    ) {
      suffix += ' copy';
    }

    const data = { ...this.settings };
    data.builder_settings = this.getBuilderSettings();
    data.builder_settings.displayName += suffix;
    data.name += suffix.toLowerCase().replace(/\s+/g, '-');
    data.clonedFrom = this.pageId;
    delete data.builder_settings.pageId;
    delete data.id;
    data.revisions = [];

    if (typeof this.content === 'undefined') {
      await this.loadContent();
    }
    return { data, content: this.content };
  },

  async select() {
    if (this.selected) {
      return;
    }
    this.selected = true;

    await this.setUpFrame();
    await this.injectAssets();

    if (this.toInject) {
      this.injectStagingElement(
        this.toInject.element,
        this.toInject.blockSelector
      );
      this.toInject = null;
    }
  },

  async deselect() {
    if (this.blocksContainer) {
      this.content = await this.getContent();
    }
    this.selected = false;
    this.frame = null;
    this.contents = null;
    this.pageWindow = null;
    this.blocksContainer = null;
  },

  async replaceContent(content, parseStyles) {
    store.commit('setPageLoadingState', true);
    try {
      if (parseStyles) content = await Importer.setInternalStyles(content);
      else content = { content };
      if (this.contents) {
        const pageContainer = this.contents.getElementById('page-container');
        if (pageContainer)
          pageContainer
            .querySelectorAll('script')
            .forEach(script => script.remove());
      }
      if (this.blocksContainer) {
        this.blocksContainer.innerHTML = content.content;
        this.blocksContainer
          .querySelectorAll('[data-gp-embed]')
          .forEach(embed => {
            if (!embed.getAttribute('data-gp-embed')) {
              embed.setAttribute('data-gp-embed', shortid.generate());
            }
            if (embeds.embeds[this.pageId])
              embeds.embeds[this.pageId][embed.getAttribute('data-gp-embed')] =
                embed.innerHTML;
          });
      }
      legacyStyleUtils.convertLegacyIDs(this.contents);
      if (this.contents) {
        this.contents.querySelectorAll('section').forEach(section => {
          section.setAttribute('data-gp-block', true);
        });
      }
      this.loadContentBuilderSettings();
      dragDropUtils.removeDragState(this.blocksContainer);
      if (this.blocksContainer) {
        this.blocksContainer
          .querySelectorAll('.gp-block-placeholder')
          .forEach(placeholder => placeholder.remove());
      }
    } catch (e) {
      console.error(e);
    }
    store.commit('setPageLoadingState', false);
  },

  replacePage({ content, ...data }) {
    this.settings = data;
    return this.replaceContent(customBase64Decode(content), true);
  },

  async getContent(isPreview, customSelector) {
    let { content } = this;
    if (this.blocksContainer) {
      const original = customSelector
        ? this.contents.querySelector(customSelector)
        : this.blocksContainer;
      content = original.cloneNode(true);
      embeds.resetEmbeds(content, this.pageId);
      textUtils.destroyEditor(content);
      await pageUtils.refreshGlobalBlocks(content);
      pageUtils.cleanContent(content);
      utils.cleanCanvas(content);
      content = content.innerHTML;
    }
    return content;
  }
};
