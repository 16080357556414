import Model from '@/data/models/Model';
import api from '@groovepages/gd-lib/api';

export default class Template extends Model {
  appends = ['isPersonal', 'isGlobal', 'isPremium', 'isRestricted', 'tags'];

  get title() {
    return (
      (this.builder_settings ? this.builder_settings.displayName : '') ||
      this.name
    );
  }

  get subType() {
    if (this.tags.includes('funnel')) {
      return 'funnel';
    }
    if (this.tags.includes('webinar')) {
      return 'webinar';
    }
    if (this.tags.includes('popup')) {
      return 'popup';
    }

    return this.type || 'page';
  }

  get isPersonal() {
    return this.user_id !== 'global';
  }

  get isGlobal() {
    return this.user_id === 'global';
  }

  get isPremium() {
    return !this.tags.includes('free');
  }

  get isRestricted() {
    return this.$store.getters.isRestricted && this.isPremium;
  }

  get tags() {
    return this.attributes.tags || [];
  }

  clonePages(site, selectedPages = []) {
    const methods = {
      page: 'cloneForPage',
      site: 'cloneForGroup',
      funnel: 'cloneForGroup',
      webinar: 'cloneForGroup',
      personal: 'cloneForPage',
    };

    const method = methods[this.subType];
    if (method) {
      return this[method](site, selectedPages);
    }
  }

  async cloneForPage(site, pages) {
    await api.post(
      `${window.gdEnv.VUE_APP_API_URL}/groovepages/pages/single-clone/${this.id}/${site.id}`,
      {
        extras: {
          builder_settings: {
            type: 'page',
            templateTags: this.tags
          },
        },
      }
    );
  }

  async cloneForSite(site, pages) {
    await api.get(
      `${window.gdEnv.VUE_APP_API_URL}/groovepages/pages/site-clone/${this.id}/${site.id}`
    );
  }

  async cloneForGroup(site, pages) {
    const body = {
      extras: {
        builder_settings: {
          type: 'page',
          templateTags: this.tags
        },
      },
    };
    if (pages.length) body.pages = pages;

    await api.post(
      `${window.gdEnv.VUE_APP_API_URL}/groovepages/pages/site-clone/${this.id}/${site.id}`,
      body
    );
  }
}
