import shortid from 'shortid';

export default {
  embeds: {},
  setEmbeds(embeds, page) {
    this.embeds[page] = embeds;
  },
  resetEmbeds(contents) {
    const embedSelectors =
      'groovevideo-widget, groovesell-promo-tools, groovesell-embed';

    if (contents)
      contents.querySelectorAll(embedSelectors).forEach(embed => {
        embed.innerHTML = '';
      });
  },
  deDuplicate(contents, page) {
    const ids = [];
    if (contents) {
      contents.querySelectorAll('[data-gp-embed]').forEach(embed => {
        const id = embed.getAttribute('data-gp-embed');
        if (ids.indexOf(id) === -1) ids.push(id);
      });
      ids.forEach(id => {
        const embeds = contents.querySelectorAll(`[data-gp-embed="${id}"]`);
        if (embeds.length > 1) {
          const newID = shortid.generate();
          embeds[1].setAttribute('data-gp-embed', newID);
          embeds[1].innerHTML = this.embeds[page][id];
          this.embeds[page][newID] = this.embeds[page][id];
        }
      });
    }
  }
};
