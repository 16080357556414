import Template from '@/data/models/Template';
import api from '@groovepages/gd-lib/api';
import Vue from 'vue';
import i18n from '../../plugins/i18';

if ('localStorage' in window) {
  i18n.locale = localStorage.getItem('lang') || 'en';
}

export default {
  namespaced: true,
  state: {
    templates: {},
    categories: [
      { id: 30, title: i18n.t('frontend_gp_cat_advertorial') },
      { id: 56, title: i18n.t('frontend_gp_cat_agency') },
      { id: 17, title: i18n.t('frontend_gp_cat_auto_cars') },
      { id: 22, title: i18n.t('frontend_gp_cat_booking_scheduling') },
      { id: 12, title: i18n.t('frontend_gp_cat_business_corporate') },
      { id: 5, title: i18n.t('frontend_gp_cat_coaches') },
      { id: 23, title: i18n.t('frontend_gp_cat_contest_giveaway') },
      { id: 9, title: i18n.t('frontend_gp_cat_courses_training') },
      { id: 34, title: i18n.t('frontend_gp_cat_cross_sells') },
      { id: 33, title: i18n.t('frontend_gp_cat_downsells') },
      { id: 7, title: i18n.t('frontend_gp_cat_ecommerce') },
      { id: 10, title: i18n.t('frontend_gp_cat_ebook') },
      { id: 24, title: i18n.t('frontend_gp_cat_event') },
      { id: 25, title: i18n.t('frontend_gp_cat_resource_download') },
      { id: 20, title: i18n.t('frontend_gp_cat_checkout') },
      { id: 21, title: i18n.t('frontend_gp_cat_consultation') },
      { id: 3, title: i18n.t('frontend_gp_cat_education') },
      { id: 55, title: i18n.t('frontend_gp_cat_finance') },
      { id: 54, title: i18n.t('frontend_gp_cat_food_beverage') },
      { id: 53, title: i18n.t('frontend_gp_cat_government') },
      { id: 6, title: i18n.t('frontend_gp_cat_health_fit') },
      { id: 8, title: i18n.t('frontend_gp_cat_lifestyle_blogging') },
      { id: 29, title: i18n.t('frontend_gp_cat_long_form_sales') },
      { id: 26, title: i18n.t('frontend_gp_cat_newsletter') },
      { id: 28, title: i18n.t('frontend_gp_cat_offer_discount_coupon') },
      { id: 1, title: i18n.t('frontend_gp_cat_optin_funnels') },
      { id: 52, title: i18n.t('frontend_gp_cat_photography') },
      { id: 51, title: i18n.t('frontend_gp_cat_real_estate') },
      { id: 11, title: i18n.t('frontend_gp_cat_retail') },
      { id: 16, title: i18n.t('frontend_gp_cat_service_maintenance') },
      { id: 27, title: i18n.t('frontend_gp_cat_signup_login') },
      { id: 50, title: i18n.t('frontend_gp_cat_software_tech') },
      { id: 13, title: i18n.t('frontend_gp_cat_startup') },
      { id: 31, title: i18n.t('frontend_gp_cat_thank_you_page') },
      { id: 14, title: i18n.t('frontend_gp_cat_travel') },
      { id: 32, title: i18n.t('frontend_gp_cat_upsells') },
      { id: 15, title: i18n.t('frontend_gp_cat_video_games') },
      { id: 4, title: i18n.t('frontend_gp_cat_video_pages') },
      { id: 35, title: i18n.t('frontend_gp_cat_wait_list_coming_soon') },
      { id: 37, title: i18n.t('frontend_gp_cat_webinar_virtual_event') },
      { id: 18, title: i18n.t('frontend_gp_cat_other') }
    ],
    templateStore: {},
    templateQuery: null,
    hasTemplatesCountChanged: false
  },
  getters: {
    allTemplates({ templates }) {
      return Object.keys(templates).map(id => new Template(templates[id]));
    },
    global(state, { allTemplates }) {
      return allTemplates.filter(({ level }) => level === 'global');
    },
    personal(state, { allTemplates }) {
      return allTemplates.filter(({ level }) => level === 'personal');
    },
    funnelTemplates(state, { allTemplates }) {
      return allTemplates.filter(({ tags }) => tags.includes('funnel'));
    },
    webinarTemplates(state, { allTemplates }) {
      return allTemplates.filter(({ tags }) => tags.includes('webinar'));
    },
    singlePageTemplates(state, { allTemplates }) {
      return allTemplates.filter(({ type }) => type === 'page');
    }
  },
  mutations: {
    setTemplateCountChanged(state, isChanged) {
      state.hasTemplatesCountChanged = isChanged;
    },
    setTemplate(state, { id, type, ...template }) {
      Vue.set(state.templates, `${type}-${id}`, { id, type, ...template });
    },
    clearTemplates(state) {
      state.templates = {};
    },
    removeTemplate(state, { id, type }) {
      Vue.remove(state.templates, `${ type }-${ id }`);
    },
    storeTemplates(state, {key, items}) {
      state.templateStore[key] = items;
    },
    setTemplateQuery(state, query) {
      state.templateQuery = query;
    },
    removeTemplateFromStore(state, id) {
      const key = state.templateQuery;
      if (state.templateStore[key].length) {
        state.templateStore[key] = state.templateStore[key].filter(template => template.id !== id);
      }
    }
  },
  actions: {
    async getTemplates({ dispatch, commit }) {
      commit('clearTemplates');
      return Promise.all([
        dispatch('getPageTemplates'),
        dispatch('getSiteTemplates')
      ]);
    },
    async getSiteTemplates({ commit }) {
      const { data: templates } = await api.get(`${ window.gdEnv.VUE_APP_API_URL }/groovepages/sites/templates/list`);
      templates.forEach(template =>
        commit('setTemplate', { ...template, type: 'site' })
      );
    },
    async getPageTemplates({ commit }) {
      const { data: templates } = await api.get(`${window.gdEnv.VUE_APP_API_URL}/groovepages/pages/templates/list`);
      templates.forEach(template =>
        commit('setTemplate', { ...template, type: 'page' })
      );
    }
  }
};
