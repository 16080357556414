// is to be used in 'Page' class at 'gp-ui/src/builder/canvas/Page/index.js'
// share methods with other 'Page' mixins (i.e pageSavingMixin, pageLoadingMixin, pageHelperMixin etc)

import Importer from '@/builder/canvas/Importer';
import canvasUtils from '@/builder/canvas/canvasUtils';
import pageUtils from '@/builder/canvas/pageUtils';
import store from '@/builder/data/store';
import legacyStyleUtils from '@/builder/legacy/legacyStyleUtils';
import shortid from 'shortid';

const BLOCK_PLACEHOLDER = `<div class="gp-loader color-1">
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" class="groove-logo speed-1">
    <path d="M19.722 19.852h19.326c-.829 8.975-4.942 14.896-12.34 17.763-7.397 2.866-14.386 1.433-20.966-4.3l6.906-6.626c2.51 1.84 4.869 2.76 7.074 2.76 2.206 0 4.268-.844 6.186-2.532l-6.186-7.065zM0 19.36h9.909c.391-4.854 2.702-7.9 6.933-9.137 4.23-1.236 7.798.038 10.702 3.822l7.73-6.309C28.764.456 21.24-1.686 12.701 1.312 4.814 4.08.581 10.097 0 19.36z"></path>
  </svg>
</div>`;

export default {
  blockInUse() {
    const used = Object.values(store.state.pages.pages)
      .filter(({ globalBlocks }) => globalBlocks)
      .reduce((ids, { globalBlocks }) => [...ids, ...(globalBlocks || [])], []);
    return used.includes(this.pageId);
  },

  refreshGlobalBlockIds() {
    this.globalBlocks = this.getGlobalBlockIds();
  },

  getBlocks() {
    const blocks = [];
    let i = 1;
    if (!this.blocksContainer) return null;
    this.blocksContainer.querySelectorAll('section').forEach(element => {
      const blockName = element.getAttribute('data-gp-block-name');
      blocks.push({
        text: blockName || `Block ${i}`,
        value: element.getAttribute('data-gp-component-id')
      });
      i += 1;
    });
    return blocks;
  },

  getGlobalBlockIds() {
    let base = this.contents;
    if (!base && this.content) {
      base = document.createElement('div');
      base.innerHTML = this.content;
    }
    if (!base) return null;
    return Array.from(
      base.querySelectorAll('[data-gp-block-type="global_block"]')
    )
      .map(e => e.dataset.gpBlockId)
      .reduce((set, id) => {
        if (!set.includes(id)) {
          set.push(id);
        }
        return set;
      }, []);
  },

  setBlockIds() {
    if (this.contents) {
      this.contents.querySelectorAll('section').forEach(section => {
        section.setAttribute('data-gp-block', true);
        section.id = section.getAttribute('data-gp-component-id');
      });
    }
  },

  setupBlockAnchor(content) {
    content.querySelectorAll('section').forEach(section => {
      const oldAnchor = section.querySelector('[data-gp-block-anchor]');
      const newAnchor = document.createElement('a');
      newAnchor.setAttribute(
        'name',
        section.getAttribute('data-gp-component-id')
      );
      newAnchor.setAttribute('data-gp-block-anchor', '');

      if (oldAnchor) {
        oldAnchor.remove();
        section.prepend(newAnchor);
      } else {
        section.prepend(newAnchor);
      }
    });
  },

  quickAddBlock(
    src,
    position,
    before = false,
    isTailwind = false,
    scroll = false
  ) {
    const placeholder = document.createElement('div');
    placeholder.classList.add('gp-block-placeholder');
    if (position) {
      let scrollTarget = 0;

      if (before) {
        this.blocksContainer.querySelector(position).before(placeholder);

        if (scroll)
          scrollTarget = this.blocksContainer.querySelector(position)
            .previousSibling.offsetTop;
      } else {
        this.blocksContainer.querySelector(position).after(placeholder);

        if (scroll)
          scrollTarget =
            this.blocksContainer.querySelector(position).offsetTop +
            this.blocksContainer.querySelector(position).getBoundingClientRect()
              .offsetHeight;
      }

      if (scroll) {
        this.contents.body.scrollTo({
          top: scrollTarget,
          behavior: 'smooth'
        });
      }
    } else {
      this.blocksContainer.querySelector('#blocks-adder').before(placeholder);
      this.contents.body.scrollTo({
        top: this.contents.body.scrollHeight,
        behavior: 'smooth'
      });
    }
    return this.blockAdded(src, isTailwind);
  },

  async fetchBlockContent(src, isTailwind, isCanvasEmpty) {
    let megamenuParent = null;
    const megamenuParentId =
      store.state.megamenu?.activeMegamenu?.megamenuParentPageId;

    if (megamenuParentId && store.state.pages.pages[megamenuParentId]) {
      megamenuParent = store.state.pages.pages[megamenuParentId];
    }

    const configID = shortid.generate();
    let { markup, ...block } = src;

    if (
      block.blockswidth &&
      store.state.pages.activePage.type === 'megamenu' &&
      isCanvasEmpty &&
      megamenuParent
    ) {
      this.blocksContainer.style.maxWidth = block.blockswidth;
      this.blocksContainer.style.minWidth = block.blockswidth;
      this.blocksContainer.style.margin = 'auto';
    }

    if (!markup) {
      const { markup: content, ..._block } = await canvasUtils.getBlock(src);
      markup = content;
      block = _block;
      block.id = src.id;
    }

    let content = markup.split('$id').join(configID);
    content.replace(/\{{(.+?)\}}/g, ($0, $1) => {
      const resource = `${window.gdEnv.VUE_APP_STORAGE_URL}/plugins/${$1}.web.js`;
      content = content.split($0).join(resource);
    });

    if (isTailwind) {
      try {
        content = await Importer.setInternalStyles(content);
      } catch (error) {
        console.error(error);
      }
    }

    return { content: isTailwind ? content.content : content, block };
  },

  setupBlockPlaceholder(placeholder) {
    const ids = [...placeholder.querySelectorAll('[data-gp-component-id]')]
      .map(element => {
        let id = '';
        if (element.hasAttribute('data-gp-text') && element.hasChildNodes()) {
          const textElClass = element.children[0].className.match(
            /gp-component-id/
          );
          id = textElClass.length
            ? [
                textElClass.input.replace('gp-component-id-', ''),
                element.getAttribute('data-gp-component-id')
              ]
            : element.getAttribute('data-gp-component-id');
        } else {
          id = element.getAttribute('data-gp-component-id');
        }

        return id;
      })
      .flat();

    let contentWithNewIds = placeholder.innerHTML;
    ids.forEach(oldId => {
      contentWithNewIds = contentWithNewIds.replace(
        new RegExp(oldId, 'gm'),
        shortid.generate()
      );
    });
    placeholder.innerHTML = contentWithNewIds;

    const styles = placeholder.querySelectorAll('[data-gp-styled-element]');
    [...styles].forEach(style => {
      const selector = style.className.split(' ').pop();
      if (selector)
        this.blocksContainer
          .querySelectorAll(`.${selector}`)
          .forEach(styleEl => styleEl.remove());
      this.blocksContainer.appendChild(style);
    });
  },

  shouldBePsudoBlock(blockNode) {
    return ['swiper-slide-content', 'gp-tab-content'].some(className =>
      blockNode.parentNode.classList.contains(className)
    );
  },

  async blockAdded(src, isTailwind = false) {
    const placeholder = this.contents.querySelector('.gp-block-placeholder');
    const isGlobalBlock = src.type === 'global_block';
    const isPsudoBlock = placeholder
      ? this.shouldBePsudoBlock(placeholder)
      : false;

    try {
      const isCanvasEmpty =
        this.blocksContainer.innerHTML.replace(
          '<section data-gp-block="true" class="gp-block-placeholder draggable-source--placed" style=""></section>',
          ''
        ) === '';

      placeholder.innerHTML = BLOCK_PLACEHOLDER;

      const { content, block } = await this.fetchBlockContent(
        src,
        isTailwind,
        isCanvasEmpty
      );

      if (!isGlobalBlock) {
        placeholder.innerHTML = isPsudoBlock
          ? content.replace(/(?<=<(\/?))section/gm, 'div')
          : content;

        this.setupBlockPlaceholder(placeholder);
      } else {
        placeholder.innerHTML = '<section></section>';
      }

      const el = placeholder.children[0];
      block.id = src.id;
      if (isPsudoBlock) {
        block.psudoType = placeholder.parentNode.classList.contains(
          'swiper-slide-content'
        )
          ? 'slider-block'
          : 'tabs-content block';
      }
      this.setupBlockAttributes(el, block);

      legacyStyleUtils.convertLegacyIDs(this.contents);
      placeholder.parentNode.replaceChild(el, placeholder);

      if (isGlobalBlock) {
        const pageContainer = this.contents.getElementById('page-container');
        await pageUtils.refreshGlobalBlocks(pageContainer, true);
        this.refreshGlobalBlockIds();
      }
    } catch (error) {
      console.error(error);
      placeholder.remove();
    } finally {
      store.commit('setPageLoadingState', false);
    }
  },

  setupBlockAttributes(el, block) {
    if (block.psudoType) {
      el.setAttribute('data-gp-component', block.psudoType);
      el.removeAttribute('data-gp-block');
      if (el.querySelector('[data-gp-block-anchor]'))
        el.querySelector('[data-gp-block-anchor]').remove();
    } else {
      el.querySelectorAll('[data-gp-container]').forEach(container => {
        container.setAttribute('data-gp-style', true)
        if (!container.hasAttribute('data-gp-component-id')) {
          container.setAttribute('data-gp-component-id', shortid.generate())
          container.setAttribute('data-gp-component', 'container')
        }
      })
      el.setAttribute('data-gp-component', 'block');
      el.setAttribute('data-gp-block-id', block.id);
      el.setAttribute('data-gp-block', true);
      el.setAttribute('data-gp-block-type', block.type ?? 'standard');
      el.setAttribute('data-gp-block-data', JSON.stringify(block));
    }
  }
};
