import { customBase64Decode, customBase64Encode } from '@/shared/utils';
import api from '@groovepages/gd-lib/api';
import shortid from 'shortid';
import i18 from '../../plugins/i18';
import store from './store';

i18.locale = localStorage.getItem('lang');

export default {
  siteId: null,
  get baseUrl() {
    return `${window.gdEnv.VUE_APP_API_URL}/groovepages`;
  },
  loadBuilderData() {
    if (store.state.builder.mode !== 'page-template') {
      return this.loadFullSiteBuilderData();
    }
    return this.loadPageTemplateBuilderData();
  },
  async loadFullSiteBuilderData() {
    let siteId = window.location.pathname.split('/');
    siteId = siteId[siteId.length - 1];
    this.siteId = siteId;
    const siteData = this.loadSiteData().then(site => {
      store.commit('setSiteData', site);
    });
    const pagesData = this.loadSitePages();
    const menus = this.loadBlocks();
    const templates = this.loadTemplates();
    if (store.state.builder.mode !== 'normal') {
      await this.loadTags();
    }
    await Promise.allSettled([siteData, menus, pagesData, templates]);
  },
  async loadTags() {
    let endpoint = `${this.baseUrl}/sites/templates/tags`;
    if (store.state.builder.mode === 'page-template') {
      endpoint = `${this.baseUrl}/pages/templates/tags`;
    }
    const result = await api.get(endpoint);
    store.commit('setTags', result);
  },
  async loadPageTemplateBuilderData() {
    await this.loadPageTemplateData();
    await this.loadBlocks();
    await this.loadTags();
  },
  async loadSiteData() {
    try {
      return await api.get(`${this.baseUrl}/sites/data/${this.siteId}`);
    } catch (e) {
      if (e.response && e.response.status === 404) {
        window.location.href = '/';
      }
      throw e;
    }
  },
  async loadBlocks() {
    const result = await api.get(`${this.baseUrl}/elements/list`);
    store.commit('setBlocksData', result);
  },
  async loadTemplates() {
    store.dispatch('templates/getTemplates');
  },
  async loadSitePages() {
    try {
      const pages = await api.get(`${this.baseUrl}/pages/list/${this.siteId}`);
      pages.forEach(page => {
        ['funnel', 'webinar'].forEach(target => {
          if (
            page.builder_settings?.type === target &&
            page.builder_settings.hasOwnProperty(`${target}_id`) &&
            !page.builder_settings[`${target}_id`]
          )
            page.builder_settings[`${target}_id`] = shortid.generate();
        });
      });
      store.commit('setPagesData', pages);
    } catch (e) {
      if (
        e.response?.status === 409 &&
        e.response.data.message === 'pages_migration_pending'
      ) {
        store.dispatch('pollSiteData');
        return Promise.resolve();
      }
      console.error(e);
    }
  },
  async loadPageTemplateData() {
    let pageId = window.location.pathname.split('/');
    pageId = pageId[pageId.length - 1];
    const result = await api.get(
      `${window.gdEnv.VUE_APP_API_URL}/groovepages/pages/data/${pageId}`
    );
    store.commit('setPagesData', [result]);
  },
  async deleteRemovedPages() {
    try {
      const deletePages = store.state.pages.pagesToDelete.map(id => {
        const deleteUrl = `${window.gdEnv.VUE_APP_API_URL}/groovepages/pages/delete/${id}`;
        return api.delete(deleteUrl);
      });
      await Promise.allSettled(deletePages);
      store.commit('pagesDeleted');
    } catch (e) {
      console.error(e);
    }
  },
  async saveSite(silent = false) {
    let screenshotHtml;
    try {
      const screenshotPage =
        store.getters.indexPage || store.state.pages.activePage;
      if (!silent)
        screenshotHtml = await screenshotPage.getFullPage({ screenshot: true });
      if (process.env.NODE_ENV === 'development' && screenshotHtml) {
        screenshotHtml = screenshotHtml
          .split(window.gdEnv.VUE_APP_FRONTEND_URL)
          .join(window.gdEnv.VUE_APP_FRONTEND_NETWORK);
      }
      const siteData = store.getters.siteSettings;
      if (screenshotHtml && !silent) {
        siteData.screenshotHtml = this.encodeContent(screenshotHtml);
      }
      const updateSiteUrl = `${window.gdEnv.VUE_APP_API_URL}/groovepages/sites/update/${store.state.site.settings.id}`;
      return api.post(updateSiteUrl, siteData);
    } catch (e) {
      console.error(e);
    }
  },
  async save(silent, saveAllPages = false, waitForSave = false) {
    try {
      store.commit('savingState', true);
      const { pages, oldHomePage } = store.state.pages;
      if (store.state.builder.mode !== 'page-template') {
        await this.saveSite(silent);
      }
      await this.deleteRemovedPages();

      const sortedPages = Object.keys(pages || {}).filter(
        pageId => !oldHomePage || (oldHomePage && pageId !== oldHomePage)
      );

      if (oldHomePage) sortedPages.unshift(oldHomePage);

      store.commit('clearOldHomePage');

      await sortedPages
        .filter(
          pageId => saveAllPages || pages[pageId].modified || pages[pageId].new
        )
        .reduce(async (previous, pageId) => {
          try {
            await previous;
            return this.savePageFromId(pageId, waitForSave);
          } catch (e) {
            console.error(e);
          }
        }, Promise.resolve());

      store.commit('setModified', false);
      if (!silent) {
        store.dispatch('addAlertMessage', {
          type: 'success',
          title: i18.t('frontend_save_successful')
        });
      }
    } catch (e) {
      console.error(e);
    } finally {
      store.commit('savingState', false);
    }
  },
  async savePage(pageId, waitForSave = true) {
    await this.savePageFromId(pageId, waitForSave);
    store.commit('savingState', false);
    store.commit('setModified', false);
  },
  updatePagesWithGlobalBlock(globalBlockId) {
    return Object.keys(store.state.pages.pages || {}).map(async pageId => {
      const parent = store.state.pages.pages[pageId];
      if (parent.globalBlocks && parent.globalBlocks.includes(globalBlockId)) {
        await this.savePageFromId(pageId, false);
        if (parent.settings.published_at) {
          store.dispatch('addAlertMessage', {
            type: 'warn',
            title: i18.t('frontend_republish_pages_after_global_block_saved'),
            text: i18.t('frontend_republish_pages_after_global_block_save')
          });
        }
      }
    });
  },
  // REVIEW: possible breaking change
  // eslint-disable-next-line sonarjs/cognitive-complexity
  async savePageFromId(pageId, waitForSave) {
    const page = store.state.pages.pages[pageId];
    const isTemplate = store.state.builder.mode === 'page-template';
    const data = await page.prepForSave(isTemplate);
    if (!data.content && page.settings.created_at) return Promise.resolve();
    data.site_id = store.state.site.settings.id;
    if (isTemplate || ['global_block', 'popup'].includes(page.type)) {
      const screenshotHtml = await page.getFullPage({ screenshot: true });
      data.screenshotHtml = this.encodeContent(screenshotHtml);
    }

    const url = page.new
      ? '/create?load=1'
      : `/update${isTemplate ? '-template' : ''}/${page.settings.id}`;

    const promise = api
      .post(`${window.gdEnv.VUE_APP_API_URL}/groovepages/pages${url}`, data)
      .then(async ({ content, builder_settings, ...settings }) => {
        if (page.new) page.setAsOld();
        page.mapBuilderSettings(builder_settings);
        page.settings = settings;
        if (content) {
          page.content = customBase64Decode(content);
        }

        const megamenuPage = page.megamenuOf
          ? store.state.pages.pages[page.megamenuOf]
          : undefined;
        if (megamenuPage && megamenuPage.megamenu !== pageId) {
          megamenuPage.megamenu = pageId;
          await this.savePage(page.megamenuOf);
        }

        // update pages with the saved global block
        if (page.type === 'global_block') {
          const promises = this.updatePagesWithGlobalBlock(page.settings.id);

          if (waitForSave) {
            await Promise.allSettled(promises);
          }
        }
      })
      .catch(e => {
        if (e.response && e.response.data.code === 'page_exists') {
          e.original = data;
        }
        throw e;
      });

    if (waitForSave) {
      await promise;
    }
  },
  async uploadScreenshot(screenshot) {
    const url = `${window.gdEnv.VUE_APP_API_URL}/groovepages/screenshot`;
    const data = new FormData();
    data.append('screenshot', screenshot);
    return api.post(url, data, { 'Content-Type': 'multipart/form-data' }, true);
  },
  async export() {
    const pages = {};

    // eslint-disable-next-line no-restricted-syntax, guard-for-in
    for (const pageId in store.state.pages.pages) {
      const page = store.state.pages.pages[pageId];

      // eslint-disable-next-line no-await-in-loop
      const content = await page.getFullPage({
        screenshot: true,
        src: 'export',
      });
      pages[pageId] = this.separateHtmlFromCss(content);
    }

    return api.post(`${window.gdEnv.VUE_APP_API_URL}/groovepages/sites/export`, {'siteID': store.state.site.settings.id, 'pages': pages});
  },
  async restoreRevision(revision) {
    store.commit('setPageLoadingState', true);
    const url = `${window.gdEnv.VUE_APP_API_URL}/groovepages/pages/update/${store.state.pages.activePage.settings.id}`;
    try {
      const data = await api.post(url, { revision });
      store.state.pages.activePage.replacePage(data);
      store.commit('setPageLoadingState', false);
    } catch (e) {
      store.commit('setPageLoadingState', false);
    }
  },
  async publishTemplate(action) {
    await this.save(true, false, true);
    if (store.state.builder.mode === 'site-template') {
      await api.get(
        `${this.baseUrl}/sites/publish-template/${store.state.site.settings.id}/${action}`
      );
    }
    try {
      const templatePages = await Object.keys(
        store.state.pages.pages || {}
      ).reduce(async (previous, id) => {
        const results = await previous;
        const page = store.state.pages.pages[id];
        const endpoint = `${this.baseUrl}/pages/publish-template/${page.settings.id}/${action}`;
        const result = await api.get(endpoint);
        results.push(result);
        return results;
      }, []);

      store.dispatch('addAlertMessage', {
        type: 'success',
        title: i18.t('frontend_save_successful')
      });
      if (
        store.state.builder.mode === 'page-template' ||
        store.state.builder.mode === 'site-template'
      ) {
        templatePages.forEach(item => {
          store.state.pages.pages[item.page_id].replacePage(item);
        });
      }
    } catch (e) {
      console.error(e);
    }
  },
  async saveTemplate() {
    await this.save(true, false, true);
    const data = JSON.parse(
      JSON.stringify(await store.state.pages.activePage.prepForSave(true))
    );

    let screenshotHtml = await store.state.pages.activePage.getFullPage({
      screenshot: true
    });
    if (process.env.NODE_ENV === 'development') {
      screenshotHtml = screenshotHtml
        .split(window.gdEnv.VUE_APP_FRONTEND_URL)
        .join(window.gdEnv.VUE_APP_FRONTEND_NETWORK);
    }
    data.screenshotHtml = customBase64Encode(screenshotHtml);

    data.template_category = 0;
    data.revisions = [];
    delete data.site_id;
    delete data.builder_settings.pageId;
    delete data.id;
    if (data.builder_settings.type === 'popup') {
      data.tags = ['popup'];
    }
    try {
      await api.post(`${this.baseUrl}/pages/create`, data);
      await this.loadTemplates();
      store.dispatch('addAlertMessage', {
        type: 'success',
        title: i18.t('frontend_save_successful')
      });
      store.commit('templates/setTemplateCountChanged', true);
    } catch (e) {
      console.error(e);
    }
  },
  async getPageContent(page) {
    const data = { pages: {} };

    const content = await store.state.pages.pages[page].getFullPage();
    data.pages[
      store.state.pages.pages[page].settings.name
    ] = customBase64Encode(content);

    return data;
  },
  saveSitePagesData(siteId, sitePagesData) {
    api.post(
      `${window.gdEnv.VUE_APP_API_URL}/groovepages/sites/pages/${siteId}`,
      {
        site_pages: JSON.parse(JSON.stringify(sitePagesData))
      }
    );
  },
  encodeContent(content, force = false) {
    if (force || (content && content.includes('<'))) {
      return customBase64Encode(content);
    }
    return content;
  },
  extractStyles(element) {
    let styles = '';
    while (element.getElementsByTagName('style').length) {
      const style = element.getElementsByTagName('style')[0];
      styles = styles.concat(style.innerHTML, '\n\n');
      style.parentNode.removeChild(style);
    }
    return styles;
  },
  separateHtmlFromCss(content) {
    const node = document.createElement('html');
    node.innerHTML = content;
    const style = this.extractStyles(node);

    return {
      html: this.encodeContent(node.outerHTML),
      css: this.encodeContent(style, true)
    };
  },
  async singlePublish(pageId) {
    const page = store.state.pages.pages[pageId];

    if (!page.isPageType()) return; // skip if page is popup/megamenu

    store.commit('publishStatus', {
      type: page.type,
      name: page.displayName,
      count: 0,
      countOf: 1
    });

    const content = await page.getFullPage({
      screenshot: false,
      publishing: true,
      checkRedirect: true
    });
    const contentToPublish = this.separateHtmlFromCss(content);

    await api.post(
      `${window.gdEnv.VUE_APP_API_URL}/groovepages/pages/${page.settings.id}/publish`,
      { ...{ websiteurl: this.websiteUrl() }, ...contentToPublish }
    );

    store.commit('publishStatus', { done: true, count: 1, countOf: 1 });
    store.dispatch('addAlertMessage', {
      type: 'success',
      title: i18.t('frontend_save_publish'),
      text: i18.t('frontentd_builder_publish_delay')
    });
  },
  async publish() {
    const { settings } = store.state.site;
    await api.get(`${this.baseUrl}/sites/pre-publish/${settings.id}`);
    store.commit('publishStatus', null);
    const pages = Object.values(store.getters.pages);
    const totalPages = pages.length;

    let count = 1;

    for (const page of pages) {
      store.commit('publishStatus', { type: page.type, name: page.displayName, count, countOf: totalPages });

      // skip if not a navigable page
      if (!page.isPageType() || page.draft_mode) continue;

      // eslint-disable-next-line no-await-in-loop
      const content = await page.getFullPage({ screenshot: false, publishing: true, checkRedirect: true });
      const contentToPublish = this.separateHtmlFromCss(content);

      await api.post(`${ this.baseUrl }/sites/pre-publish-pages/${ settings.id }`, {...{pageId: page.settings.id, name : page.settings.name,websiteurl : this.websiteUrl()},...contentToPublish});
      count++;
    }

    await api.post(`${this.baseUrl}/sites/publish/${settings.id}`, {
      ...settings
    });
    store.commit('publishStatus', {
      done: true,
      count: totalPages,
      countOf: totalPages
    });
    store.dispatch('addAlertMessage', {
      type: 'success',
      title: i18.t('frontend_save_publish'),
      text: i18.t('frontentd_builder_publish_delay')
    });
  },
  async unpublish() {
    const { settings } = store.state.site;
    await api.get(`${ this.baseUrl }/sites/pre-publish/${ settings.id }`);
    await api.post(`${ this.baseUrl }/sites/publish/${ settings.id }`, { ...settings });

    store.dispatch('addAlertMessage',{
      'type': 'success',
      'title': i18.t('frontend_save_publish'),
      'text': i18.t('frontentd_builder_publish_delay')
    });
  },
  websiteUrl() {
    const { settings } = store.state.site;
    const {publish_folder} = settings;

    let url = '/';

    if( publish_folder )
      url += `${publish_folder}/`;

    return url;
  },
  async getLinksBySiteID(siteID) {
    try {
      let response = await api.get(`${this.baseUrl}/sites/${siteID}/get-links-in-folder`);

      return response.data;
    } catch (e) {
      return [];
    }
  }
};
