import api from '@groovepages/gd-lib/api';
import { customBase64Decode } from '../../shared/utils';
import Model from './Model';

export default class Page extends Model {
  get pageInstance() {
    const { default: CanvasPage } = require('@/builder/canvas/Page');
    return new CanvasPage(this.getAttributes());
  }

  async loadContent(mode = 'standard') {
    if (this.settings.id) {
      const type = mode !== 'standard' ? 'template-data' : 'data';
      const endpoint = `${window.gdEnv.VUE_APP_API_URL}/groovepages/pages/${type}/${this.settings.id}`;
      const result = await api.get(endpoint);
      this.content = result.content || '';
    }
  }

  async getContent() {
    try {
      let content = this.content || '';

      if (content.length && content.includes('</')) {
        return content;
      }

      content = await customBase64Decode(content).trim();

      if (content.length && !content.startsWith('<')) {
        // decode previously double encoded popups
        try {
          const doubleDecoded = await customBase64Decode(
            content.substr(0, content.indexOf('\n'))
          ).trim();
          if (doubleDecoded.includes('</')) {
            content = doubleDecoded;
          }
        } catch (err) {
          console.error(err);
        }
      }
      return content || '';
    } catch (error) {
      console.error(error);
      // todo: revert to earlier revision
      // this.fallbackToEarlierRevision();
    }
  }
}
