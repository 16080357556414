import Vue from 'vue';
import VueI18n from 'vue-i18n';

import p_en from '../../../packages-lang/en.json';
import p_pt from '../../../packages-lang/pt.json';

import en from '../lang/en.json';
import pt from '../lang/pt.json';

Vue.use(VueI18n);

const messages = {
  en : {...en,...p_en},
  pt : {...pt,...p_pt}
};

const i18n = new VueI18n({
  locale: 'en',
  messages,
  silentTranslationWarn: true,
  missing(locale, path) {
    console.warn('Missing translation:', {locale, path});
  }
});

export default i18n;
