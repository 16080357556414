import uikitConfig from '@groovepages/gp-page-ui-kit/tailwind';
import AlignLeft from '@fortawesome/fontawesome-free/svgs/solid/align-left.svg';
import AlignRight from '@fortawesome/fontawesome-free/svgs/solid/align-right.svg';
import AlignCenter from '@fortawesome/fontawesome-free/svgs/solid/align-center.svg';
import AlignJustify from '@fortawesome/fontawesome-free/svgs/solid/align-justify.svg';
import AllSides from '@groovepages/gd-ui-kit/assets/all-sides.svg';
import TopRightCorner from '@groovepages/gd-ui-kit/assets/top-right-corner.svg';
import TopLeftCorner from '@groovepages/gd-ui-kit/assets/top-left-corner.svg';
import BottomRightCorner from '@groovepages/gd-ui-kit/assets/bottom-right-corner.svg';
import BottomLeftCorner from '@groovepages/gd-ui-kit/assets/bottom-left-corner.svg';
import CloseIcon from '@groovepages/gd-ui-kit/assets/close-icon.svg';
import BorderSolid from '@groovepages/gd-ui-kit/assets/border-solid.svg';
import BorderDashed from '@groovepages/gd-ui-kit/assets/border-dashed.svg';
import BorderDotted from '@groovepages/gd-ui-kit/assets/border-dotted.svg';
import SideBottom from '@groovepages/gd-ui-kit/assets/side-bottom.svg';
import SideTop from '@groovepages/gd-ui-kit/assets/side-top.svg';
import SideLeft from '@groovepages/gd-ui-kit/assets/side-left.svg';
import SideRight from '@groovepages/gd-ui-kit/assets/side-right.svg';
import HAlignCenter from '@groovepages/gd-ui-kit/assets/flex/hor_align_center.svg';
import HAlignEnd from '@groovepages/gd-ui-kit/assets/flex/hor_align_end.svg';
import HAlignStart from '@groovepages/gd-ui-kit/assets/flex/hor_align_start.svg';
import HAlignStretch from '@groovepages/gd-ui-kit/assets/flex/hor_align_stretch.svg';
import HJustifyAround from '@groovepages/gd-ui-kit/assets/flex/hor_justify_around.svg';
import HJustifyBetween from '@groovepages/gd-ui-kit/assets/flex/hor_justify_between.svg';
import HJustifyCenter from '@groovepages/gd-ui-kit/assets/flex/hor_justify_center.svg';
import HJustifyEnd from '@groovepages/gd-ui-kit/assets/flex/hor_justify_end.svg';
import HJustifyStart from '@groovepages/gd-ui-kit/assets/flex/hor_justify_start.svg';
import VAlignCenter from '@groovepages/gd-ui-kit/assets/flex/vert_align_center.svg';
import VAlignEnd from '@groovepages/gd-ui-kit/assets/flex/vert_align_end.svg';
import VAlignStart from '@groovepages/gd-ui-kit/assets/flex/vert_align_start.svg';
import VAlignStretch from '@groovepages/gd-ui-kit/assets/flex/vert_align_stretch.svg';
import VJustifyAround from '@groovepages/gd-ui-kit/assets/flex/vert_justify_around.svg';
import VJustifyBetween from '@groovepages/gd-ui-kit/assets/flex/vert_justify_between.svg';
import VJustifyCenter from '@groovepages/gd-ui-kit/assets/flex/vert_justify_center.svg';
import VJustifyEnd from '@groovepages/gd-ui-kit/assets/flex/vert_justify_end.svg';
import VJustifyStart from '@groovepages/gd-ui-kit/assets/flex/vert_justify_start.svg';
import styleFunctions from './styleFunctions';

export default () => ({
  text: {
    font: {
      label: 'font',
      classPrefix: 'font',
      options: 'fonts'
    },
    size: {
      label: 'Size',
      classPrefix: 'text',
      options: 'textSizes'
    },
    weight: {
      label: 'weight',
      classPrefix: 'font',
      options: 'fontWeights',
      module: 'fontWeights'
    },
    color: {
      label: 'color',
      classPrefix: 'text',
      options: 'colors',
      module: 'textColors',
      opacityProperty: 'color',
      tailwind: false,
      getter: styleFunctions.getColorOpacity,
      setter: styleFunctions.setColorOpacity
    },
    tracking: {
      label: 'letter spacing',
      classPrefix: 'tracking',
      options: 'tracking'
    },
    leading: {
      label: 'line height',
      classPrefix: 'leading',
      options: 'leading'
    },
    alignment: {
      label: 'alignment',
      classPrefix: 'text',
      options: ['left', 'center', 'right', 'justify'],
      sides: {
        left: AlignLeft,
        center: AlignCenter,
        right: AlignRight,
        justify: AlignJustify
      }
    }
  },
  background: {
    color: {
      label: 'color',
      classPrefix: 'bg',
      options: 'colors',
      module: 'backgroundColors',
      opacityProperty: 'background-color',
      tailwind: false,
      getter: styleFunctions.getColorOrOverlayColor,
      setter: styleFunctions.setColorOrOverlayColor,
      selector: '[data-section-overlay]'
    },
    image: {
      getter: styleFunctions.getBackgroundImage,
      setter: styleFunctions.setBackgroundImage,
      tailwind: false
    },
    parallax: {
      getter: styleFunctions.getParallax,
      setter: styleFunctions.setParallax,
      tailwind: false
    },
    size: {
      label: 'size',
      classPrefix: 'bg',
      options: 'backgroundSize',
      sides: {
        auto: 'Auto',
        cover: 'Cover',
        contain: 'Contain'
      }
    },
    repeat: {
      label: 'repeat',
      classPrefix: 'bg',
      options: ['repeat', 'no-repeat', 'repeat-x', 'repeat-y']
    },
    alignment: {
      label: 'alignment',
      classPrefix: 'bg',
      options: [
        'center',
        'bottom',
        'top',
        'left',
        'left-bottom',
        'left-top',
        'right',
        'right-bottom',
        'right-top'
      ]
    }
  },
  border: {
    color: {
      label: 'color',
      classPrefix: 'border',
      options: 'colors',
      module: 'borderColors',
      opacityProperty: 'border-color',
      tailwind: false,
      getter: styleFunctions.getColorOpacity,
      setter: styleFunctions.setColorOpacity
    },
    style: {
      label: 'style',
      classPrefix: 'border',
      options: ['solid', 'dashed', 'dotted', 'none'],
      sides: {
        none: CloseIcon,
        solid: BorderSolid,
        dashed: BorderDashed,
        dotted: BorderDotted
      }
    },
    width: {
      label: 'width',
      classPrefix: 'border',
      options: 'borderWidths',
      getter: styleFunctions.getSidedProperty,
      setter: styleFunctions.setSidedProperty,
      sides: {
        '': AllSides,
        '-t': SideTop,
        '-b': SideBottom,
        '-l': SideLeft,
        '-r': SideRight
      }
    },
    radius: {
      label: '--',
      classPrefix: 'rounded',
      options: 'borderRadius',
      getter: styleFunctions.getSidedProperty,
      setter: styleFunctions.setSidedProperty,
      sides: {
        '-tl': TopLeftCorner,
        '-tr': TopRightCorner,
        '-bl': BottomLeftCorner,
        '-br': BottomRightCorner
      }
    },
    custom: {
      classPrefix: 'rounded',
      label: '',
      options: 'borderRadius',
      sides: Object.keys(uikitConfig.borderRadius)
    }
  },
  spacing: {
    margin: {
      label: 'margin',
      classPrefix: 'm',
      options: 'margin',
      getter: styleFunctions.getSidedProperty,
      setter: styleFunctions.setSidedProperty,
      sides: {
        t: 'icon-dock-top',
        b: 'icon-dock-bottom',
        l: 'icon-dock-left',
        r: 'icon-dock-right'
      }
    },
    padding: {
      label: 'padding',
      classPrefix: 'p',
      options: 'padding',
      getter: styleFunctions.getSidedProperty,
      setter: styleFunctions.setSidedProperty,
      sides: {
        t: 'icon-dock-top',
        b: 'icon-dock-bottom',
        l: 'icon-dock-left',
        r: 'icon-dock-right'
      }
    },
    styles: {
      label: 'alignment',
      classPrefix: 'position',
      options: ['left', 'center', 'right'],
      sides: {
        left: 'left',
        center: 'center',
        right: 'right'
      }
    }
  },
  sizing: {
    width: {
      label: 'Width',
      classPrefix: 'w',
      getter: styleFunctions.flattenOptionsAndGetProperty,
      options: [
        'auto',
        {
          label: 'px',
          values: Object.keys(uikitConfig.absoluteWidths)
        },
        {
          label: 'Relative to page',
          values: Object.keys(uikitConfig.pageRelativeWidths)
        },
        {
          label: 'Relative to parent',
          values: Object.keys(uikitConfig.parentRelativeWidths)
        },
        'full',
        'screen'
      ]
    },
    minWidth: {
      label: 'min width',
      classPrefix: 'min-w',
      module: 'minWidth',
      options: 'minWidth'
    },
    maxWidth: {
      label: 'max width',
      classPrefix: 'max-w',
      module: 'maxWidth',
      options: 'maxWidth'
    },
    height: {
      label: 'Height',
      classPrefix: 'h',
      getter: styleFunctions.flattenOptionsAndGetProperty,
      options: [
        'auto',
        {
          label: 'px',
          values: Object.keys(uikitConfig.absoluteheights)
        },
        {
          label: 'Relative to page',
          values: Object.keys(uikitConfig.pageRelativeHeights)
        },
        {
          label: 'Relative to parent',
          values: Object.keys(uikitConfig.parentRelativeHeights)
        },
        'screen'
      ]
    }
  },
  other: {
    shadow: {
      label: 'shadow',
      classPrefix: 'shadow',
      options: 'shadows',
      module: 'shadows'
    },
    opacity: {
      label: 'Transparency',
      classPrefix: 'opacity',
      options: 'opacity',
      getter: styleFunctions.reverseTailwindOptions
    }
  },
  layout: {
    direction: {
      label: 'direction',
      classPrefix: 'flex',
      options: ['row', 'col'],
      sides: {
        row: 'Horizontal',
        col: 'Vertical'
      }
    },
    alignH: {
      label: 'align',
      classPrefix: 'items',
      options: ['start', 'center', 'end', 'stretch'],
      sides: {
        start: HAlignStart,
        center: HAlignCenter,
        end: HAlignEnd,
        stretch: HAlignStretch
      }
    },
    alignV: {
      label: 'align',
      classPrefix: 'items',
      options: ['start', 'center', 'end', 'stretch'],
      sides: {
        start: VAlignStart,
        center: VAlignCenter,
        end: VAlignEnd,
        stretch: VAlignStretch
      }
    },
    justifyH: {
      label: 'justify',
      classPrefix: 'justify',
      options: ['start', 'center', 'end', 'between', 'around'],
      sides: {
        start: HJustifyStart,
        center: HJustifyCenter,
        end: HJustifyEnd,
        between: HJustifyBetween,
        around: HJustifyAround
      }
    },
    justifyV: {
      label: 'justify',
      classPrefix: 'justify',
      options: ['start', 'center', 'end', 'between', 'around'],
      sides: {
        start: VJustifyStart,
        center: VJustifyCenter,
        end: VJustifyEnd,
        between: VJustifyBetween,
        around: VJustifyAround
      }
    }
  },
  advanced: {
    id: {
      label: "Element's ID attribute",
      getter: styleFunctions.getIDProperty,
      setter: styleFunctions.setIDProperty,
      tailwind: false
    },
    classes: {
      label: "Element's CLASS attribute",
      getter: styleFunctions.getCustomClasses,
      setter: styleFunctions.setCustomClasses,
      tailwind: false
    },
    display: {
      label: 'display',
      classPrefix: '',
      options: [
        'block',
        'inline-block',
        'inline',
        'table',
        'table-row',
        'table-cell',
        'hidden',
        'flex',
        'inline-flex'
      ]
    },
    customCss: {
      label: 'Custom CSS',
      getter: styleFunctions.getCustomCss,
      setter: styleFunctions.setCustomCss,
      tailwind: false
    }
  }
});
