import templates from '@/data/store/templates';
import core from '@groovepages/gd-lib/core-store';
import domains from '@groovepages/gd-lib/domain-store';
import records from '@groovepages/gd-lib/records-store';
import subdomains from '@groovepages/gd-lib/subdomains-store';
import Vue from 'vue';
import Vuex, { mapState } from 'vuex';
import border from './modules/border';
import clipboard from './modules/clipboard';
import megamenu from './modules/megamenu';
import shadow from './modules/shadow';
import sidebar from './modules/sidebar';
import spacing from './modules/spacing';
import specialPages from './modules/special-pages';
import builder from './state/builder';
import pages from './state/pages';
import site from './state/site';

Vue.use(Vuex);

Vue.mixin({
  computed: {
    ...mapState({
      lang: state => state.core.lang
    })
  }
});
const store = new Vuex.Store({
  modules: {
    core,
    builder,
    clipboard,
    pages,
    site,
    specialPages,
    templates,
    spacing,
    sidebar,
    shadow,
    records,
    domains,
    subdomains,
    megamenu,
    border
  },
  mutations: {
    setModified(state, modified) {
      state.builder.modified = modified;
      if (state.pages.activePage) {
        state.pages.activePage.modified = modified;
      }
    },
    setRedirect(state, redirect) {
      state.builder.redirect = redirect;
    },
    setPluginSettingsModified(state) {
      state.builder.pluginSettingsModified = !state.builder
        .pluginSettingsModified;
    }
  },
  getters: {
    pluginSiteSettings: state => {
      if (!state.pages.activePage.getBlocks) {
        return {};
      }
      return {
        modified: state.builder.pluginSettingsModified, // needed to make this getter react on setPluginSettingsModified
        integrations:
          (state.core.user &&
            state.core.user.metadata &&
            state.core.user.metadata.integrations) ||
          [],
        blocks: state.pages.activePage.getBlocks(),
        popups: Object.keys(state.pages.pages || {})
          .filter(id => state.pages.pages[id].type === 'popup')
          .map(id => ({
            text: state.pages.pages[id].displayName,
            value: id
          })),
        pages: Object.keys(state.pages.pages || {})
          .filter(
            id =>
              !['megamenu', 'popup', 'global_block'].includes(
                state.pages.pages[id].type
              )
          )
          .map(id => ({
            text: state.pages.pages[id].displayName,
            value: id
          }))
      };
    },
    onPageSiteSettings: (state, getters) => {
      const settings = {
        pages: {},
        popups: [],
        navigation: getters.onPageNavigation
      };
      const pageIds = Object.values(state.pages.pages)
        .filter(
          ({ type }) => !['popup', 'megamenu', 'global_block'].includes(type)
        )
        .map(({ pageId }) => pageId);

      Object.keys(state.pages.pages || {}).forEach(pageId => {
        const page = state.pages.pages[pageId];
        if (pageIds.includes(pageId)) {
          settings.pages[pageId] = { url: page.settings?.name || 'Page' };
        } else if (page.type === 'popup') {
          settings.popups.push({
            ...page.popup,
            name: page.displayName,
            id: pageId,
            pages: page?.popup?.pages?.includes('all')
              ? pageIds
              : page?.popup?.pages
          });
        }
      });
      return settings;
    },
    user: state => state.core.user,
    isLiteUser: state =>
      state.core.user?.roles?.indexOf('groovepages_user') === -1 ||
      (state.core.user.plan &&
        Array.isArray(state.core.user.plan) &&
        state.core.user.plan.length > 0 &&
        state.core.user.plan[0].toLowerCase() === 'startup'),
    isWhitelistUser: state => state.core.user?.metadata?.is_whitelisted,
    isRestricted: (state, getters) =>
      getters.isLiteUser && !getters.isWhitelistUser,
    isMasterAdmin: (state, getters) =>
      getters.user.admin &&
      ['b48d34ec-b678-470d-a13e-73f6ee7f85c9'].includes(getters.user.id)
  }
});
export default store;
